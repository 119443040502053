// In 2014 the smallest screen was  480 x 320.  Code accordingly in order
// to cover phones that have this resolution.

import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
// import { RouteProps } from 'react-router-dom';
// import { OmitNative } from 'react-router';
// import { ReactNode } from 'react';

import "font-awesome/css/font-awesome.min.css";
import "./styles/base.css";
import "./styles/color.css";
import versions from "./versions";
import { ethers } from "ethers";
import { SubmitProblem } from "./components/submitproblem";
import { ProblemList } from "./components/problem-list";
import { Registration } from "./components/registration";
import i18n from "i18next";
import { _t } from "./i18n";
import {
  ViewType,
  isViewType,
  sleep,
  isString,
  isNumber,
  isDate,
  StandardException,
} from "./sdptypes";
import { Problem } from "./api";
import * as ls from "./local-storage";
import { critiquePassword } from "./password";
import { Login } from "./components/login";
import { Profile } from "./components/profile";
import { UsersList } from "./components/userslist";
import {
  Config,
  getConfig,
  getCookie,
  setCookie,
  oneHour,
  oneCentury,
  getUsersList,
  setPasswordAsync,
} from "./api";
import { Comodoro_Rivadavia } from "./osm";
import LandingPage from "./components/landing-page";
import { GoogleOAuthProvider } from "google-oauth-gsi";

// where is this?
type Timeout = any;

const { git, hg } = versions;

// enum ViewType {
//   report_pot_hole = "report-pot-hole",
//   report_missing_sign = "report-missing-sign",
//   report_damaged_sign = "report-damaged-sign",
//   network = "network",
//   projects = "projects"
// }
//
let googleProvider: any = undefined;

// how much earlier from the time of th expiry should a ping be sent prior to the session expiry?

const earliness = 600; /*s*/ // 10 minutes should be good.
const fetchTimeout = 18000; /*ms*/
const errorTimeout = 28000;
const mainContract_moonAlpha_address =
  "0xA56a520cE78c9F5b9860BB6267afC35A69D96ca3";
const mercERC20_contract_address = "0x37822de108AFFdd5cDCFDaAa2E32756Da284DB85";

const emailAddressPattern = /[a-z0-9._]+@[a-z0-9._]+\.[a-z0-9]{2,3}/;

// set to true when we have more than one network.
const networkSelectionEnabled = null;

// set to true once the backend is mature enough to use contracts
const contractCodeEnabled = null;

const defaultPhotoEncoding = "data:image/jpeg;base64";
// 1x1 transparent pixel in base64
const defaultImage =
  "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/epN7QAAAABJRU5ErkJggg==";

// cookiePrefix should change variable types or the setState order change.
const cookiePrefix = "6b27904/8bbc766ea2f2-";
//let cookieConter = 0;

//const useState = (val: any) => {
//  const oldCookiedValue = getCookie(cookiePrefix + ++cookieConter);
//  const [bareVal, bareSetVal] = useState(oldCookiedValue !== null ? oldCookiedValue : val);
//  return [bareVal, (newVal) => {
//      setCookie(cookiePrefix + cookieConter, newVal, oneHour);
//      bareSetVal(newVal);
//  }]
//}

function App() {
  // STATES
  const defaultView: ViewType = "projects";
  const [config, setConfig] = useState<null | Config>(null);
  //const cookiePrefix = config ? (config.git + '/' + config.hg + '-') : '';
  // console.log( cookiePrefix );
  const [gettingConfig, setGettingConfig] = useState(false);
  // Cookies used by these are set in login.tsx.
  const [activeUserNumber, onlySetActiveUserNumber] = useState<null | number>(
    null,
  );
  const [sessionExpiry, onlySetSessionExpiry] = useState(new Date(0));
  const [sessionId, onlySetSessionId] = useState<null | string>("");
  const [downloadSpeed, setDownloadSpeed] = useState<null | number>(null);

  const setSessionInfo = (
    newSessionId: null | string,
    newActiveUser: null | number,
    expiry: Date,
  ) => {
    if (!expiry || !expiry.getTime) {
      console.log(expiry);
      throw new Error("Invalid date passed to setSessionInfo");
    }
    if (typeof newActiveUser === "string") {
      // should always be false but ...
      newActiveUser = parseInt(newActiveUser);
    }
    const expiryString = expiry.toUTCString();
    ls.set("expiry", expiryString);
    onlySetSessionExpiry(expiry);
    onlySetActiveUserNumber(newActiveUser);
    onlySetSessionId(newSessionId);
    if (newSessionId === null && newActiveUser !== null) {
      console.error(
        `Incorrect use of setSessionInfo: `,
        newSessionId,
        newActiveUser,
        expiry,
      );
      throw new Error("Incorrect use of setSessionInfo");
    }
    if (typeof newActiveUser === "string") {
      // should always be false but ...
      newActiveUser = parseInt(newActiveUser);
    }

    onlySetSessionExpiry(expiry);
    onlySetActiveUserNumber(newActiveUser);
    onlySetSessionId(newSessionId ?? "");
    // If I use stringify here, the reading code must parse
    document.cookie = `session=${JSON.stringify(newSessionId)};expires=${expiryString};path=/`;
    document.cookie = `activeUserNumber=${JSON.stringify(newActiveUser)};expires=${expiryString};path=/`;
  };
  useEffect(() => {
    (async () => {
      const maybeErrorMessage = getCookie("error_message");
      const maybeActiveUserNumber = getCookie("activeUserNumber");
      const maybeSessionId = getCookie("session");
      const maybeUserInfo = getCookie("userInfo");
      console.log("Cookie values:", {
        maybeActiveUserNumber,
        maybeSessionId,
        maybeUserInfo,
      });
      if (
        maybeSessionId &&
        maybeActiveUserNumber &&
        isNumber(maybeActiveUserNumber) &&
        isString(maybeSessionId)
      ) {
        // Validate values here.
        // good, ask backend...
        const resp = await fetch("/private-api/ping", {
          headers: {
            session_id: maybeSessionId,
          },
        });
        const { expiry, success } = await resp.json();
        const expiry_Date = new Date(expiry);

        if (success && isDate(expiry_Date)) {
          // The cookies are good.
          setSessionInfo(maybeSessionId, maybeActiveUserNumber, expiry_Date);
          if (
            view !== "profile" &&
            view !== "projects" &&
            view !== "users" &&
            view !== "report"
          ) {
            setView(defaultView);
          }

          if (maybeUserInfo === null) {
            return () => {};
          }

          setLoggedIn(true);
          const { admin, donor, provider, hasPassword } = maybeUserInfo as {
            admin: boolean;
            donor: boolean;
            provider: boolean;
            hasPassword: boolean;
          };

          setRoles([
            ...(admin ? ["admin"] : []),
            ...(donor ? ["donor"] : []),
            ...(provider ? ["provider"] : []),
          ]);

          // We don't actually have any use of these here,
          // as the profile page will load all of this and more.
          // maybe there is familyname and givenname (as Google has)
          // const maybeFamilyName = getCookie("familyNames");
          // const maybeGivenName = getCookie("givenNames");
          //
          // console.log( maybeFamilyName, maybeGivenName );
          // if (maybeFamilyName) {
          //   setActiveUserFamilyNames(maybeFamilyName);
          // }
          // if (maybeGivenName) {
          //   setActiveUserGivenNames(maybeGivenName);
          // }

          return () => {};
        } // if
      } else if (maybeErrorMessage) {
        // if
        setErrorMessage(maybeErrorMessage);
      }
      document.cookie = `session="";expires=Wed, 21 Oct 1970 07:28:00 GMT;path=/`;
      document.cookie = `activeUserNumber=0;expires=Wed, 21 Oct 1970 07:28:00 GMT;path=/`;
    })();
    return () => {};
  }, []);

  const [roles, setRoles] = useState(
    ((rolesArray) => {
      if (!rolesArray || !activeUserNumber) {
        return [];
      }
      const rolesList = rolesArray[activeUserNumber];
      if (!rolesList) return [];
      else return rolesArray[activeUserNumber];
    })(ls.get("roles")),
  );

  // console.log( sessionId );
  const [loggedIn, setLoggedIn] = useState(!!sessionId);

  const [ethereumAddresses, setEthereumAddresses] = useState<Array<string>>([]);
  const [activeUserEthereumAddress, setActiveUserEthereumAddress] = useState<
    null | string
  >(null);
  const [activeUserBitcoincashAddress, setActiveUserBitcoincashAddress] =
    useState<string>("");
  const [activeUserGivenNames, onlySetGivenNames] = useState([]);
  const [activeUserFamilyNames, onlySetFamilyNames] = useState([]);

  const [canConnectToBackend, setCanConnectToBackend] = useState<
    null | boolean
  >(null);
  const [canConnectToEthereum, setCanConnectToEthereum] = useState<
    null | boolean
  >(null);

  const [connected, setConnected] = useState(false);
  const [emailAddressError, setEmailAddressError] = useState("");
  const [emailAddress, setOnlyEmailAddress] = useState("");
  const [errorMessage, _onlySetErrorMessage] = useState("");
  const [familyNamesError, setFamilyNamesError] = useState("");
  const [givenNamesError, setGivenNamesError] = useState("");
  const [networkName, setNetworkName] = useState("Moonbase Alpha");
  const [password2Error, setPassword2Error] = useState("");
  const [password2, onlySetPassword2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, onlySetPassword] = useState("");
  const [selectedProblemId, setSelectedProblemId] = useState<null | number>(
    null,
  );
  const [subjectUserFamilyNames, setSubjectUserFamilyNames] = useState("");
  const [subjectUserGivenNames, setSubjectUserGivenNames] = useState("");
  const [successMessage, onlySetSuccessMessage] = useState("");
  const [tooManyCalls, setTooManyCalls] = useState(false);
  const [view, onlySetView] = useState<ViewType>(
    ls.get(cookiePrefix + activeUserNumber + "view", defaultView),
  );
  const setView = (newView: ViewType) => {
    onlySetView(newView);
    window.history.pushState(newView, "null");
    ls.set(cookiePrefix + activeUserNumber + "view", newView);
  };
  // Admin States
  const [usersList, setUsersList] = useState<UserTableRow[] | null>(null);
  const [currentCity, setCurrentCity] = useState(
    "Municipio de Comodoro Rivadavia",
  );
  const [problemState, setProblemState] = useState("search");
  const [problemSet, onlySetProblemSet] = useState<Array<Problem>>([]);
  const setProblemSet = (ps: Array<Problem>) => {
    const cityH: { [id: string]: number } = {};
    let mostPopular: null | string = null;

    ps.forEach((p) => {
      const c = p.city;
      if (!cityH[c]) {
        cityH[c] = 1;
      } else {
        cityH[c] += 1;
      }
      if (mostPopular === null || cityH[c] > cityH[mostPopular]) {
        mostPopular = c;
      }
    });
    if (ps.length === 0) {
      //console.log(`problemSet: []`);
    } else {
      // console.log(
      //   `problemSet: length=${ps.length}, markersSet:${!!ps[0]
      //     .marker}, imagesSet:${!!ps[0].image}`,
      // );
      // @ts-ignore
      setCurrentCity(mostPopular);
    }
    onlySetProblemSet(ps);
  };
  const [reportWhat, setReportWhat] = useState("");
  const [oSMEnabled, setOSMEnabled] = useState(true);
  const [zoom, setZoom] = useState<number | null>(null);

  const [problemListRef, setProblemListRef] = useState<{ current: any }>({
    current: null,
  });
  const [problemTableHeaderRef, setProblemTableHeaderRef] = useState<{
    current: any;
  }>({ current: null });
  const [problemTableBodyRef, setProblemTableBodyRef] = useState<{
    current: any;
  }>({ current: null });
  const [problemTableRef, setProblemTableRef] = useState<{ current: any }>({
    current: null,
  });
  const [showLandingPage, onlySetShowLandingPage] = useState(
    !ls.get(`hideLandingPage-${activeUserNumber}`, loggedIn),
  );
  const setShowLandingPage = (newValue: boolean) => {
    if (activeUserNumber)
      ls.set(`hideLandingPage-${activeUserNumber}`, !newValue);
    onlySetShowLandingPage(newValue);
  };
  const [showProjectSubmissionForm, setShowProjectSubmissionForm] =
    useState(false);
  const [loadedProblems, setLoadedProblems] = useState(false);
  const [challengePhrase, setChallengePhrase] = useState<string | null>(null);
  const [challengePhraseExpiry, setChallengePhraseExpiry] =
    useState<Date | null>(null);
  const [challengeExpiresSoon, setChallengeExpiresSoon] = useState(true);

  const isOwner = false;
  const cityMaintainerContractAddress = "0";
  const stableCoins = [];
  const contractPausing = false;
  const contractStarting = false;
  const contractPaused = false;
  const contractSignCost = 0.1; // BCH

  const hasEthereumWallet = !!window?.ethereum;

  const getBestLanguage = useCallback((languageAcceptance: string) => {
    try {
      const newLanguage = languageAcceptance.split(/-/)[0];
      if (typeof newLanguage === "string") {
        if (newLanguage === "es") {
          return "es-AR";
        }
      }
    } catch (e) {}
    return "en-US";
  }, []);

  const cities = {
    "Municipio de Comodoro Rivadavia": Comodoro_Rivadavia,
  };

  const language = getBestLanguage(navigator.language);

  const fetchChallenge = (failure: (e: any) => void) => {
    fetch(`/private-api/getChallenge`, {
      headers: {
        "Accept-Language": language,
      },
    })
      .then((response) => {
        response
          .json()
          .then((jSON) => {
            if (jSON) {
              const { success, session_id, expiry } = jSON;
              const expiryDate = new Date(expiry);
              console.log(session_id);
              setChallengePhrase(session_id);
              setChallengePhraseExpiry(expiryDate);
              const now = new Date();
              const delta = expiryDate.getTime() - now.getTime() - 60_000;
              setChallengeExpiresSoon(false);
              setTimeout(() => {
                setChallengeExpiresSoon(true);
              }, delta);
            }
          })
          .catch(failure);
      })
      .catch(failure);
  };

  useEffect(() => {
    const failure = () => {
      setChallengeExpiresSoon(false);
      setTimeout(() => setChallengeExpiresSoon(true), 15_000);
    };
    if (challengeExpiresSoon) fetchChallenge(failure);
  }, [challengeExpiresSoon]);

  const streetOrder: "big-endian" | "little-endian" =
    language === "es-AR" ? "big-endian" : "little-endian";

  const setActiveUserGivenNames = (names) => {
    if (typeof names === "string") {
      names = names.split(/ +/);
    }
    if (typeof names === "object" && typeof names.length === "number") {
      names = names.filter((name) => name !== "");
    }
    onlySetGivenNames(names);
    if (names.length === 0) {
      setGivenNamesError(_t("register.blank-givennames"));
    } else {
      setGivenNamesError("");
    }
  };

  const setActiveUserFamilyNames = (names) => {
    if (typeof names === "string") {
      names = names.split(/ +/);
    }
    if (typeof names === "object" && typeof names.length === "number") {
      names = names.filter((name) => name !== "");
    }
    onlySetFamilyNames(names);
    if (names.length === 0) {
      setFamilyNamesError(_t("register.blank-familynames"));
    } else {
      setFamilyNamesError("");
    }
  };

  const setEmailAddress = (emailAddress_p: string) => {
    if (emailAddress_p.match(emailAddressPattern) || emailAddress_p === "") {
      setEmailAddressError("");
    } else {
      setEmailAddressError("Not a valid email address");
    }
    setOnlyEmailAddress(emailAddress_p);
  };

  const setPassword = (text) => {
    onlySetPassword(text);
    setPasswordError(_t(critiquePassword(text)));
  };

  const setPassword2 = (text) => {
    if (text !== password) {
      setPassword2Error(_t("register.passwords-dont-match"));
    } else {
      setPassword2Error("");
    }
    onlySetPassword2(text);
  };

  const setErrorMessage = (text) => {
    if (text === "") {
      _onlySetErrorMessage("");
      return;
    }

    _onlySetErrorMessage(text);
    setTimeout(_onlySetErrorMessage.bind(""), 5000);
  };

  const setSuccessMessage = (text) => {
    if (text === "") {
      onlySetSuccessMessage("");
    } else {
      onlySetSuccessMessage(text);
      setTimeout(onlySetSuccessMessage.bind(""), 5000);
    }
  };
  const [connectionCheckIntervalLength, setConnectionCheckIntervalLength] =
    useState(0);

  // REFERENCES
  const signCostInputRef = useRef<HTMLInputElement | null>(null);
  const ethersProviderRef = useRef<any>(null);
  const cityMaintainerContractRef = useRef(null);
  const newCoinNameInputRef = useRef(null);
  const newCoinAddressInputRef = useRef<null | HTMLInputElement>(null);
  const newCoinSymbolInputRef = useRef(null);
  const pingHandleRef = useRef<Timeout | null>(null);
  const connectionCheckIntervalRef = useRef<Timeout | null>(null);

  i18n.changeLanguage(language);
  const setLanguageRoutine = () => {
    i18n.changeLanguage(getBestLanguage(navigator.language));
  };

  useEffect(() => {
    if (config && config.websiteName) {
      const { git, hg } = config;
      document.title = config.websiteName;
      const prefix = cookiePrefix;

      setCookie(`6b27904/8bbc766ea2f2-view`, "", new Date(0));
      setCookie(`gobierno-reversions`, "", new Date(0));

      //const cookiedView = ls.get(prefix+'view', view);
      //if (cookiedView) {
      //  setView(cookiedView);
      //}
      //
      if (getCookie("activeUserNumber") === null) {
        // correct any inconsistent states here.
        if (loggedIn) {
          setLoggedIn(false);
          console.error("no activeUserNumber but loggedIn set to true");
        }
        if (view != "projects" && view !== "login" && view !== "register") {
          console.error(`no activeUserNumber but view set to ${view}`);
          setView("projects");
          ls.set("view", "projects");
        }
      } else {
        // the ping process will indirectly keep the cookies fresh.
      }
      //const beforeUnloadHandler = (e) => {
      //  e.preventDefault();
      //  setCookie(prefix+'view', view, oneHour);
      //  setCookie(prefix+'activeUserNumber', activeUserNumber, oneHour);
      //  setCookie(prefix+activeUserNumber+'showLandingPage', showLandingPage, oneHour);
      //};
      //window.addEventListener('beforeunload', beforeUnloadHandler);

      return () => {
        //window.removeEventListener('beforeunload', beforeUnloadHandler);
      };
    }

    return () => {};
  }, [config]);

  useEffect(setLanguageRoutine);

  const checkConfiguration = () => {
    if (gettingConfig) return;
    try {
      console.log("Getting configuration...");
      setConnectionCheckIntervalLength(2_000_000);
      setGettingConfig(true);
      getConfig()
        .then((cfg) => {
          console.log(
            "New config is ",
            cfg,
            "backendRunning:",
            cfg?.backendRunning,
          );

          setConnectionCheckIntervalLength(
            cfg?.backendRunning ? 180_000 : 2_000,
          );
          const { backendRunning } = cfg;
          setCanConnectToBackend(backendRunning);
          setConfig(cfg);
          setGettingConfig(false);
          if (cfg.googleClientId !== "") {
            googleProvider = new GoogleOAuthProvider({
              clientId: cfg.googleClientId,
              onScriptLoadError: () => console.log("onScriptLoadError"),
              onScriptLoadSuccess: () => console.log("onScriptLoadSuccess"),
            });
          }
        })
        .catch((e) => {
          console.error(e);
          setConnectionCheckIntervalLength(1_000);
          setGettingConfig(false);
        });
    } catch (e) {
      console.error(e);
      setGettingConfig(false);
      setConnectionCheckIntervalLength(0);
      setCanConnectToBackend(false);
    }
  }; //, [setCanConnectToBackend, setConfig, setConnectionCheckIntervalLength, gettingConfig]);

  // Prevent nonconsensual refreshes
  useEffect(() => {
    const onConfirmRefresh = function (event) {
      event.preventDefault();
      return (event.returnValue = _t("main.unload-confirmation"));
    };

    if (view === "report" || view === "profile") {
      window.addEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
      return () =>
        window.removeEventListener("beforeunload", onConfirmRefresh, {
          capture: true,
        });
    }
    return () => console.log("undoing nothing");
  }, [view]);

  useEffect(() => {
    connectionCheckIntervalRef.current = setInterval(
      checkConfiguration,
      connectionCheckIntervalLength,
    );

    return () => {
      clearInterval(connectionCheckIntervalRef.current);
      connectionCheckIntervalRef.current = null;
    };
  }, [connectionCheckIntervalLength]);

  const onceInABlock = useCallback((tx, fn) => {
    setTimeout(fn, 24000);
  }, []);

  const loggedInGuard = () => {
    if (!loggedIn) {
      setErrorMessage(_t("main.login"));
      return false;
    }
    return true;
  };

  const logout = () => {
    setSessionInfo(null, null, new Date(0));
    onlySetGivenNames([]);
    onlySetFamilyNames([]);
    onlySetPassword("");
    onlySetPassword2("");
    setEmailAddress("");
    setActiveUserEthereumAddress("");
    setConnected(false);
    setUsersList(null);
    setProblemState("search");
    setReportWhat("");
    setView("projects");
    setRoles([]);
    setLoggedIn(false);
    setConnected(false);
  };

  const pingServer = async () => {
    if (sessionExpiry.getTime() !== 0) {
      const timeLeft = Math.floor(
        (sessionExpiry.getTime() - new Date().getTime()) / 1000,
      );
      if (timeLeft < earliness && timeLeft > 0 && timeLeft % 7 === 0) {
        console.log(`pinging backend... using session '${sessionId}'`);
        const pingResponse = await Promise.any([
          fetch("/private-api/ping", {
            headers: {
              session_id: sessionId ?? undefined,
            },
          }),
          sleep(fetchTimeout),
        ]);
        if (pingResponse) {
          const pingJSON = await pingResponse.json();
          const { success, expiry } = pingJSON;
          if (success && expiry) {
            setSessionInfo(sessionId, activeUserNumber, new Date(expiry));
          } else {
            console.log(pingJSON);
          }
        } else {
          setConnected(false);
        }
      } else if (timeLeft < 0) {
        if (loggedIn) {
          logout();
          setErrorMessage(_t("main.session-logout"));
        } else {
          logout();
        }
      }
      if (
        timeLeft < 100 /*s*/ &&
        ((timeLeft < 25 /*s*/ && timeLeft > 0) ||
          Math.floor(timeLeft) % 10 /*s*/ === 0)
      )
        console.log(`Expiry in ${timeLeft} seconds`);
    }
  };

  const provider = window?.ethereum;

  async function connectEthereumExtension() {
    let provider = window?.ethereum;
    if (provider)
      return provider
        .request({
          method: "eth_requestAccounts",
        })
        .then((newAccounts) => {
          //console.log("setting ethereum addresses");
          setEthereumAddresses(newAccounts);
          setCanConnectToEthereum(true);
          return true;
        })
        .catch((e) => {
          console.log(e.message);
          return false;
        });
    return new Promise<boolean>((result, reject) => {
      result(true);
    });
  }

  useEffect(() => {
    // @ts-ignore window
    if (provider) {
      provider.on("accountsChanged", setEthereumAddresses);

      return () => {
        provider // Or window.ethereum if you don't support EIP-6963.
          .removeListener("accountsChanged", setEthereumAddresses);
      };
    }
    return () => {};
    // @ts-ignore window
  }, [provider]);

  useEffect(() => {
    let history: any;
    if ((history = window.history) && history.pushState) {
      // push initial state.
      history.pushState(view, "");
      window.addEventListener("popstate", (ev) => {
        const { state } = ev;
        if (isViewType(state)) {
          onlySetView(state);
        }
      });
    }
  }, []);

  useEffect(
    () => {
      if (pingHandleRef.current === null) {
        pingHandleRef.current = setInterval(pingServer, 5000 /*ms*/);
      }

      return () => {
        if (pingHandleRef.current) {
          clearInterval(pingHandleRef.current);
          pingHandleRef.current = null;
        } // if
      }; // ()=>{}
    }, // ()=>{}
  );

  // const appendStableCoin = useCallback(
  //   (symbol, name, address) => {
  //     setStableCoins([...stableCoins, { symbol, name, address }]);
  //   },
  //   [stableCoins]
  // );

  /*
    display for the console and the screen the detail of the exception.
  */
  const handleError = useCallback((exception: StandardException) => {
    const { stack } = exception;
    console.log(exception);
    console.log(stack);
    try {
      const { error, data } = exception;
      if (error && error.message) {
        const { code } = error;
        if (code === 4200) {
          setTooManyCalls(true);
        }
        setErrorMessage(error.message);
      } else if (data) {
        setErrorMessage(data.message);
      } else if (exception.message) {
        console.log({ message: exception.message, exception });
        if (exception.message.match(/Unexpected token '<'/)) {
          setErrorMessage("Unable to load from the backend.");
        } else {
          setErrorMessage(exception.message);
        }
      } else {
        // WTAF?
        setErrorMessage("Unknown error");
      }
    } finally {
      setTimeout(() => setErrorMessage(""), errorTimeout);
    }
  }, []);

  const noPort3000 = () => {
    try {
      if (document.location.href === "http://localhost:3000/") {
        // redirect to port 80...
        setErrorMessage("port is set to 3000.  redirecting to port 80...");
        setTimeout(() => {
          document.location = "http://localhost";
        }, 2500);
      }

      // Initialize the provider
      if (window.ethereum && ethersProviderRef.current === null) {
        const handler = (accounts: Array<string>) => {
          setActiveUserEthereumAddress(accounts[0]);
        };
        ethersProviderRef.current = new ethers.providers.Web3Provider(
          window.ethereum,
        ).on("accountsChanged", handler);
      }
    } catch (e) {
      console.log("Error with port fixing");
    }
  };

  useEffect(noPort3000, []);

  const networkChanged = (chainId) => {
    let networkName_;

    switch (chainId) {
      case "0x507":
        setNetworkName("Moonbase Alpha");
        setCityMaintainerContractAddress(mainContract_moonAlpha_address);
        setStableCoinAddress(mercERC20_contract_address);
        setStableCoins([
          {
            name: "Mercury",
            symbol: "MERC",
            address: "0x37822de108AFFdd5cDCFDaAa2E32756Da284DB85",
          },
        ]);
        break;
      default:
        networkName_p = "Not Connected";
        setActiveUserEthereumAddress("Only Moonbase Alpha Supported");
        setNetworkName(networkName_);
        setCanConnectToEthereum(false);
        return;
    } // switch
  };

  if (window.ethereum) {
    window.ethereum.on("chainChanged", networkChanged);
  }

  try {
    const loadUserList = (ev) => {
      Promise.any([getUsersList(sessionId), sleep(fetchTimeout)])
        .then((userListResponse) => {
          if (userListResponse && userListResponse.status === 200) {
            userListResponse
              .json()
              .then((JSONResponse) => {
                const { success } = JSONResponse;
                if (success) {
                  setUsersList(JSONResponse.data);
                } else {
                  setErrorMessage(JSONResponse.errorMessage);
                } // if
              })
              .catch(handleError);
          } else {
            setErrorMessage("Unable to load user list");
          }
        })
        .catch((exc) => {
          handleError(exc);
        });
    };

    const setFlag = async (
      id,
      flag_name,
      flag_value: boolean,
    ): Promise<boolean> => {
      setErrorMessage("");
      const setFlagResp = await Promise.any([
        fetch("/private-api/setUserFlag", {
          headers: {
            activeUserAddress: activeUserEthereumAddress,
            user_id: id,
            session_id: sessionId,
            [flag_name]: flag_value ? "1" : "0",
          },
        }),
        sleep(fetchTimeout),
      ]);
      if (!setFlagResp) {
        setErrorMessage(_t("g.timeout"));
        return false;
      }
      const setFlagRespJSON = await setFlagResp.json();
      const { success, errorMessage: msg } = setFlagRespJSON;
      if (!success) {
        setErrorMessage(msg);
      } else {
        setSuccessMessage(_t("g.done"));
      }
      return success;
    };

    const setUserAdmin = (id, value) => {
      console.log(id, value);
      return setFlag(id, "admin", value).then((success) => {
        if (success)
          for (let i = 0; i < usersList.length; ++i) {
            if (usersList[i].id === id) {
              setUsersList([
                ...usersList.slice(0, i),
                { ...usersList[i], admin: value },
                ...usersList.slice(i + 1),
              ]);
            }
          } // for if
        return success;
      });
    };

    const setUserProvider = (id, value) => {
      console.log(id, value);
      return setFlag(id, "provider", value).then((success) => {
        if (success)
          for (let i = 0; i < usersList.length; ++i) {
            if (usersList[i].id === id) {
              setUsersList([
                ...usersList.slice(0, i),
                { ...usersList[i], provider: value },
                ...usersList.slice(i + 1),
              ]);
            }
          } // for if
        return success;
      });
    };

    const setUserDonor = (id, value) => {
      console.log(id, value);
      return setFlag(id, "donor", value).then((success) => {
        if (success)
          for (let i = 0; i < usersList.length; ++i) {
            if (usersList[i].id === id) {
              setUsersList([
                ...usersList.slice(0, i),
                { ...usersList[i], donor: value },
                ...usersList.slice(i + 1),
              ]);
            }
          } // for if
        return success;
      });
    };
    const updateSignCost = (ev) => {
      console.log(cityMaintainerContractRef.current, signCostInputRef.current);
      if (
        cityMaintainerContractRef.current === null ||
        signCostInputRef.current === null
      ) {
        console.log(
          "error: No city contract reference or no sign cost input tag.",
        );
        setErrorMessage(_t("backend.internal-error"));
        return;
      }
      const signCostInputValue = signCostInputRef.current.value * 1.0;
      cityMaintainerContractRef.current
        .setSignCost(signCostInputValue)
        .then((tx) => {
          console.log({ tx });
          setContractSignCost(signCostInputValue);
        })
        .catch(handleError);
    };

    const startContract = (ev) => {
      if (cityMaintainerContractRef.current === null) {
        setErrorMessage("Internal Error: No city contract reference");
        return;
      }
      setContractStarting(true);
      cityMaintainerContractRef.current
        .setPaused(false)
        .then((tx) => {
          console.log({ tx });
          onceInABlock(tx, () => {
            setContractStarting(false);
            setContractPaused(false);
          });
        })
        .catch((e) => {
          setContractStarting(false);
          handleError(e);
        });
    };

    const deleteProblem = (problemId: number) => {
      let i;
      for (i = 0; problemSet[i].id !== problemId && i < problemSet.length; ++i);
      if (i === problemSet.length) return;
      const problem = problemSet[i];
      const newProblemSet = [
        ...problemSet.slice(0, i),
        ...problemSet.slice(i + 1),
      ];
      problem.marker.remove();
      setProblemSet(newProblemSet);
    };

    const appendProblem = (
      problemId: number,
      problemType: string,
      streetNumber: number | null,
      streetName: string,
      crossStreetName: string | null,
      description: string,
      latitude: number,
      longitude: number,
      marker: L.Marker,
      image: string | null,
    ) => {
      setProblemSet([
        ...problemSet,
        {
          id: problemId,
          problemType,
          streetNumber,
          streetName,
          title:
            (streetNumber
              ? streetOrder === "big-endian"
                ? `${streetName} ${streetNumber}`
                : `${streetNumber} ${streetName}`
              : streetName) + (crossStreetName ? `@ ${crossStreetName}` : ""),
          city: "Comodoro Rivadavia",
          body: description,
          location: { decLatitude: latitude, decLongitude: longitude },
          marker,
          image,
        },
      ]);
      setSelectedProblemId(problemId);
    };

    // also misremembered as passedParams
    const passedProps = {
      activeUserBitcoincashAddress,
      activeUserEthereumAddress,
      activeUserFamilyNames,
      activeUserGivenNames,
      activeUserNumber,
      appendProblem,
      canConnectToBackend,
      canConnectToEthereum,
      challengePhrase,
      challengePhraseExpiry,
      config,
      connectEthereumExtension,
      defaultImage,
      defaultPhotoEncoding,
      deleteProblem,
      ethereumAddresses,
      emailAddress,
      emailAddressError,
      ethersProviderRef,
      familyNamesError,
      fetchTimeout,
      givenNamesError,
      googleProvider,
      handleError,
      hasEthereumWallet,
      language,
      loggedIn,
      oSMEnabled,
      password,
      password2,
      password2Error,
      passwordError,
      problemSet,
      problemState,
      reportWhat,
      roles,
      sessionExpiry,
      sessionId,
      setActiveUserBitcoincashAddress,
      setActiveUserEthereumAddress,
      setActiveUserFamilyNames,
      setActiveUserGivenNames,
      setCanConnectToBackend,
      setCanConnectToEthereum,
      setConnected,
      setEmailAddress,
      setErrorMessage,
      setLoggedIn,
      setOSMEnabled,
      setPassword,
      setPassword2,
      setPassword2Error,
      setPasswordError,
      setProblemSet,
      setRoles,
      setSessionInfo,
      setShowProjectSubmissionForm,
      setSubjectUserFamilyNames,
      setSubjectUserGivenNames,
      setSuccessMessage,
      setUserAdmin,
      setUserDonor,
      setUserProvider,
      setView,
      setZoom,
      showProjectSubmissionForm,
      streetOrder,
      subjectUserFamilyNames,
      subjectUserGivenNames,
      tooManyCalls,
      usersList,
      view,
      websiteName: config?.websiteName ?? "",
      zoom,
    };
    const pauseContract = (ev) => {
      if (cityMaintainerContractRef.current === null) {
        setErrorMessage("Internal Error: No city contract reference");
        return;
      }
      setContractPausing(true);
      cityMaintainerContractRef.current
        .setPaused(true)
        .then((tx) => {
          console.log({ tx });
          onceInABlock(tx, () => {
            setContractPaused(true);
            setContractPausing(false);
          });
        })
        .catch((e) => {
          setContractPausing(false);
          handleError(e);
        });
    };

    const handleAddStableCoin = (ev) => {
      try {
        if (newCoinAddressInputRef.current && ethersProviderRef.current) {
          const stableCoinAddress = newCoinAddressInputRef.current.value;
          const stableCoinName = newCoinNameInputRef.current.value;
          const stableCoinSymbol = newCoinSymbolInputRef.current.value;
          appendStableCoin(stableCoinSymbol, stableCoinName, stableCoinAddress);
        } else {
          setErrorMessage(`ethersProvider not set or form element missing`);
        }
      } catch (e) {
        handleError(e);
      }
    };

    return (
      <div className="App-body">
        {showLandingPage && (
          <LandingPage
            config={config}
            setShowLandingPage={setShowLandingPage}
            activeUserNumber={activeUserNumber}
          />
        )}
        <div className="App">
          <div
            className={connected ? "topbar connected" : "topbar disconnected"}
          >
            <div className="horizontallyArranged">
              {networkSelectionEnabled && (
                <div
                  onClick={() => setView("networks")}
                  className={view === "networks" ? "selected" : "unselected"}
                >
                  {_t("main.network")}
                </div>
              )}
              {canConnectToBackend && loggedIn && (
                <div onClick={() => loggedInGuard() && setView("report")}>
                  <div>
                    <div
                      className={view === "report" ? "selected" : "unselected"}
                    >
                      <span className="fa fa-flag heading-icon" />
                      <span className="headingText">
                        {_t("main.menu-report")}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {canConnectToBackend && (
                <div
                  onClick={() => setView("projects")}
                  className={view === "projects" ? "selected" : "unselected"}
                >
                  <span className="fa fa-list heading-icon" />
                  <span className="headingText">
                    {_t("main.menu-problems")}
                  </span>
                </div>
              )}

              {canConnectToBackend && loggedIn && (
                <div
                  onClick={() => loggedInGuard() && setView("profile")}
                  className={view === "profile" ? "selected" : "unselected"}
                >
                  <span className="fa fa-user heading-icon" />
                  <span className="headingText">{_t("main.profile")}</span>
                </div>
              )}

              {canConnectToBackend &&
              roles &&
              roles.find((x) => x === "admin") ? (
                <div
                  onClick={() => setView("users")}
                  className={view === "users" ? "selected" : "unselected"}
                >
                  <span className="fa fa-users heading-icon" />
                  <span className="headingText">{_t("main.menu-users")}</span>
                </div>
              ) : (
                false
              )}

              {canConnectToBackend === false && (
                <div className="unselected">{_t("main.disconnected")}</div>
              )}

              {canConnectToBackend && loggedIn && (
                <div onClick={logout} className="unselected">
                  <span className="fa fa-times heading-icon" />{" "}
                  <span className="headingText sessionHeadingText">
                    {_t("main.logout")}
                  </span>
                </div>
              )}

              {canConnectToBackend && config?.loginsAllowed && !loggedIn && (
                <div className="unselected">
                  <span
                    onClick={() =>
                      setView(view === "register" ? "login" : "register")
                    }
                    className="fa fa-user heading-icon"
                  />
                  <span className="headingText sessionHeadingText">
                    {view === "register" ? (
                      <span onClick={() => setView("login")}>
                        {_t("main.login")}
                      </span>
                    ) : view === "login" ? (
                      <span onClick={() => setView("register")}>
                        {_t("main.register")}
                      </span>
                    ) : (
                      <>
                        <span onClick={() => setView("login")}>
                          {_t("main.login")}
                        </span>
                        /
                        <span onClick={() => setView("register")}>
                          {_t("main.register")}
                        </span>
                      </>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
          <header
            className={
              config?.testnet
                ? "App-body App-body-testnet"
                : "App-body App-body-mainnet"
            }
          >
            {!canConnectToBackend && (
              <div style={{ width: "100vw", height: "94vh" }}>
                <img
                  alt=""
                  style={{ width: "100vw", height: "94vh" }}
                  src="/logo512-4.png"
                />
              </div>
            )}
            {view === "report" && (
              <SubmitProblem
                cities={cities}
                currentCity={currentCity}
                close={() => setView("projects")}
                problemListRef={problemListRef}
                problemTableHeaderRef={problemTableHeaderRef}
                problemTableBodyRef={problemTableBodyRef}
                problemTableRef={problemTableRef}
                {...passedProps}
                problemSet={problemSet}
              />
            )}
            {view === "profile" && (
              <Profile
                activeUserBitcoinCashAddress={activeUserBitcoincashAddress}
                activeUserEthereumAddress={activeUserEthereumAddress ?? ""}
                config={config}
                downloadSpeed={downloadSpeed}
                fetchTimeout={fetchTimeout}
                hasEthereumWallet={hasEthereumWallet}
                onlySetPassword2={onlySetPassword2}
                onlySetPassword={onlySetPassword}
                password={password}
                password2={password2}
                password2Error={password2Error}
                passwordError={passwordError}
                sessionId={sessionId}
                setActiveUserBitcoinCashAddress={
                  setActiveUserBitcoincashAddress
                }
                setActiveUserEthereumAddress={setActiveUserEthereumAddress}
                setErrorMessage={setErrorMessage}
                setLoggedIn={setLoggedIn}
                setPassword2Error={setPassword2Error}
                setPassword={setPassword}
                setPassword2={setPassword2}
                setPasswordError={setPasswordError}
                setSuccessMessage={setSuccessMessage}
                userNumber={activeUserNumber}
              />
            )}
            {
              false /* Note that there is no activeUserBitcoinCashAddress passed here! */
            }
            {view === "projects" && (
              <ProblemList
                cities={cities}
                currentCity={currentCity}
                {...passedProps}
                loadedProblems={loadedProblems}
                setDownloadSpeed={setDownloadSpeed}
                setLoadedProblems={setLoadedProblems}
                setProblemListRef={setProblemListRef}
                setProblemTableHeaderRef={setProblemTableHeaderRef}
                setProblemTableBodyRef={setProblemTableBodyRef}
                setProblemTableRef={setProblemTableRef}
                setSelectedProblemId={setSelectedProblemId}
                selectedProblemId={selectedProblemId}
                sessionId={sessionId ?? ""}
              />
            )}
            {view === "networks" && (
              <article>Moonbase Network es el unico suportado...</article>
            )}
            {view === "register" && <Registration {...passedProps} />}
            <UsersList
              {...passedProps}
              loadUserList={loadUserList}
              usersList={usersList}
            />
            {config && config?.loginsAllowed && view === "login" && (
              <Login
                {...passedProps}
                fetchChallenge={fetchChallenge}
                activeUserEthereuAddress={activeUserEthereumAddress}
              />
            )}
            {contractCodeEnabled && (
              <article>
                Network Name: {networkName} <br />
                City Maintainer Contract Address:{" "}
                {cityMaintainerContractAddress} <br />
                <div>
                  {contractSignCost !== null && (
                    <span>
                      Contract Sign Cost: US${" "}
                      {isOwner &&
                      contractPaused === false &&
                      contractStarting === false ? (
                        <>
                          f
                          <input
                            ref={signCostInputRef}
                            defaultValue={contractSignCost}
                          />
                          <button onClick={updateSignCost}>Update</button>
                        </>
                      ) : (
                        contractSignCost
                      )}{" "}
                    </span>
                  )}
                  {contractPausing === false &&
                    contractStarting === false &&
                    contractPaused !== null &&
                    (contractPaused ? (
                      <b>
                        Contract Paused{" "}
                        {isOwner && (
                          <button onClick={startContract}>
                            Start Contract
                          </button>
                        )}
                      </b>
                    ) : (
                      isOwner && (
                        <button onClick={pauseContract}>Stop Contract</button>
                      )
                    ))}
                  {contractStarting && (
                    <div> The contract is starting up... </div>
                  )}
                  {contractPausing && (
                    <div> The contract is about to pause... </div>
                  )}
                  {isOwner && "You're the owner!"}

                  {cityMaintainerContractAddress !== null && isOwner && (
                    <div style={{ color: "black", backgroundColor: "white" }}>
                      <h3>Add Stable Coin</h3>
                      <label>Symbol </label>
                      <input
                        ref={newCoinSymbolInputRef}
                        type="text"
                        defaultValue="MERC"
                      />
                      <label>Name</label>
                      <input
                        ref={newCoinNameInputRef}
                        defaultValue="Mercury"
                        type="text"
                      />{" "}
                      <br />
                      <label>Address (direccion) </label>{" "}
                      <input
                        ref={newCoinAddressInputRef}
                        type="text"
                        defaultValue="0x37822de108AFFdd5cDCFDaAa2E32756Da284DB85"
                      />{" "}
                      <br />
                      <button onClick={handleAddStableCoin}>
                        Acceptar esta como un stable coin con U$D
                      </button>
                    </div>
                  )}

                  {
                    //   cityMaintainerContractAddress !== null && (
                    //   <div
                    //     style={{ color: "black", backgroundColor: "lightblue" }}
                    //   >
                    //     {stableCoins.map((coin) => (
                    //       <b key={coin.address}>{coin.name}</b>
                    //     ))}
                    //   </div>
                    // )
                  }

                  {cityMaintainerContractAddress !== null && isOwner && (
                    <div style={{ color: "red", backgroundColor: "black" }}>
                      <h3>Add new funded foot</h3>
                      <label> Cartel: </label> Arreglo de Ruta 3 <br />
                      <label> latitude </label> 45º.85565 S
                      <label> longitude </label> 67.47663 OE <br />
                      <button>create rectangulo de fondos</button>
                    </div>
                  )}
                  {activeUserEthereumAddress !== "" &&
                    "Your activeUserEthereumAddress is " +
                      activeUserEthereumAddress}
                  {sessionId && <div>Your session id is set</div>}
                  {sessionExpiry.getUTCFullYear() !== 1970 &&
                    sessionExpiry.toISOString()}
                </div>
              </article>
            )}
            {errorMessage && errorMessage !== "" && (
              <div id="error-box">
                <div className="box-top">
                  <div
                    className="fa fa-close"
                    onClick={() => setErrorMessage("")}
                  />
                </div>
                <div>{errorMessage}</div>
              </div>
            )}
            {successMessage && successMessage !== "" && (
              <div id="success-box">
                <div className="box-top">
                  <div
                    className="fa fa-close"
                    onClick={() => setSuccessMessage("")}
                  />
                </div>
                <div>{successMessage}</div>
              </div>
            )}
          </header>
        </div>
        <div className="neutronStar">{_t("main.rotate-phone")}</div>
        {config?.confuse && (
          <div>
            git: {config.git} hg: {config.hg}
          </div>
        )}
      </div>
    );
  } catch (e) {
    console.error(e);
    const ep = e as { message: string; stack: any };
    if (ep)
      return (
        <div>
          {ep.message} {ep.stack}
        </div>
      );
    return <div />;
  }
}

export default App;
