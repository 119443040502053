import React from "react";
import { useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import { ViewType, isStandardException } from "../sdptypes";
import buffer from "buffer";
import { _t } from "../i18n/index";
import { critiquePassword } from "../password";
import { AddressSelectionList } from "./addressselectionlist";
import { Config } from "../api";
import { GoogleButton } from "./google";
const emailRegex = /^[a-z0-9._]+@[a-z0-9._]+\.[a-z0-9]{2,3}$/;

const Buffer = buffer.Buffer;
interface Props {
  activeUserFamilyNames: string[];
  activeUserGivenNames: string[];
  ethereumAddresses: string[];
  config: null | Config;
  connectEthereumExtension: () => Promise<boolean>;
  emailAddressError: string;
  emailAddress: string;
  ethersProviderRef: any;
  googleProvider: any;
  hasEthereumWallet: boolean;
  loggedIn: boolean;
  password: string;
  password2: string;
  passwordError: string;
  password2Error: string;
  setActiveUserEthereumAddress: (account: string | null) => void;
  setConnected: (connected: boolean) => void;
  setEmailAddress: (emailAddress: string) => void;
  setErrorMessage: (errorMessage: string) => void;
  setActiveUserFamilyNames: (activeUserFamilyNames: string[]) => void;
  setActiveUserGivenNames: (activeUserGivenNames: string[]) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setPassword: (string) => void;
  setPassword2: (string) => void;
  setSessionInfo: (sessionId: string, userId: number, expiry: Date) => void;
  setView: (view: ViewType) => void;
  setSubjectUserFamilyNames: (string) => void;
  setSubjectUserGivenNames: (string) => void;
  subjectUserFamilyNames: string;
  subjectUserGivenNames: string;
}

export function Registration(props: Props) {
  const {
    activeUserGivenNames,
    activeUserFamilyNames,
    config,
    connectEthereumExtension,
    ethereumAddresses,
    ethersProviderRef,
    googleProvider,
    hasEthereumWallet,
    setActiveUserEthereumAddress,
    setConnected,
    setErrorMessage,
    setActiveUserFamilyNames,
    setActiveUserGivenNames,
    setLoggedIn,
    setSessionInfo,
    setSubjectUserFamilyNames,
    setSubjectUserGivenNames,
    setView,
    subjectUserFamilyNames,
    subjectUserGivenNames,
  } = props;

  const [accountToRegister, setAccountToRegister] = useState<string | null>(
    null,
  );
  const [requestingAccount, setRequestingAccount] = useState(false);
  const [userNumber, setUserNumber] = useState<number | null>(null);
  const [registering, setRegistering] = useState<boolean>(false);

  const [givenNames, setGivenNames] = useState<string>("");
  const [givenNamesError, setGivenNamesError] = useState<string>("");

  const [familyNames, setFamilyNames] = useState<string>("");
  const [familyNamesError, setFamilyNamesError] = useState<string>("");

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailAddressError, setEmailAddressError] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const [password2, setPassword2] = useState<string>("");
  const [password2Error, setPassword2Error] = useState<string>("");

  const [ready, setReady] = useState(false);
  const [showAddressSelection, setShowAddressSelection] = useState(false);

  const validationTimeout = useRef<any>(null);
  const givenNamesRef = useRef<HTMLInputElement>(null);
  const familyNamesRef = useRef<HTMLInputElement>(null);
  const emailAddressRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const password2Ref = useRef<HTMLInputElement>(null);

  const handleAccountChange = (accounts: Array<string>) => {
    console.log(accounts);
  };

  const validateFields = () => {
    let ready_flag = true;
    if (givenNamesRef.current && givenNamesRef.current?.value === "") {
      setGivenNamesError(_t("register.blank-givennames"));
      ready_flag = false;
    }

    if (givenNamesRef.current && givenNamesRef.current?.value === "") {
      setFamilyNamesError(_t("register.blank-familynames"));
      ready_flag = false;
    }

    if (
      emailAddressRef.current?.value &&
      !emailRegex.test(emailAddressRef.current?.value)
    ) {
      setEmailAddressError(_t("register.bad-email-address"));
      ready_flag = false;
    }

    if (
      (password2Ref.current?.value ?? "") !== "" ||
      accountToRegister === null
    ) {
      const critique = critiquePassword(password2Ref.current?.value ?? "");
      if (critique !== "") {
        ready_flag = false;
      }
      setPasswordError(critique);
    } else {
      setPasswordError("");
    }

    if (password2Ref.current?.value != password2Ref.current?.value) {
      setPassword2Error(_t("register.passwords-dont-match"));
      ready_flag = false;
    }

    if (ready_flag) {
      setReady(ready_flag);
    }
  };

  const fieldChanged = (ev) => {
    if (validationTimeout.current != null) {
      clearTimeout(validationTimeout.current);
      validationTimeout.current = null;
    }
    setReady(false);
    setGivenNamesError("");
    setFamilyNamesError("");
    setEmailAddressError("");
    setPasswordError("");
    setPassword2Error("");
    setSubjectUserGivenNames(givenNamesRef.current?.value ?? "");
    setSubjectUserFamilyNames(familyNamesRef.current?.value ?? "");
    setEmailAddress(emailAddressRef.current?.value ?? "");
    setPassword(passwordRef.current?.value ?? "");
    setPassword2(password2Ref.current?.value ?? "");
    validationTimeout.current = setTimeout(validateFields, 1500);
  };

  //const [debugText, setDebugText] = useState("");
  const connectMetaMask = (ev) => {
    ev.preventDefault();
    connectEthereumExtension();
  };

  const doRegistration = async (ev) => {
    try {
      ev.preventDefault();

      setRegistering(true);
      let activeUserEthereumAddress_2 = accountToRegister;

      if ((!password || password === "") && !accountToRegister) {
        throw new Error(
          "No Ethereum address and no password sent for authentication.",
        );
      }

      const messageHash = (() => {
        let data: { [id: string]: string };
        if (!accountToRegister) {
          data = {
            familyNames: subjectUserFamilyNames,
            givenNames: subjectUserGivenNames,
          };
        } else {
          data = {
            familyNames: subjectUserFamilyNames,
            givenNames: subjectUserGivenNames,
            activeUserAddress: accountToRegister,
          };
        }

        // @ts-ignore
        return ethers.utils.hashMessage(JSON.stringify(data)).slice(0, 128);
      })();

      const proposedSessionId = messageHash;

      const headers: [string, string][] = [["session_id", proposedSessionId]];

      if (emailAddress > "") headers.push(["email_address", emailAddress]);

      if (password && password > "") {
        headers.push(["password", password]);
      }
      headers.push(["Content-Type", "text/json"]);
      headers.push(["Accept-Language", "en-US"]);

      const hexMessage2 =
        "0x" + Buffer.from(proposedSessionId, "utf-8").toString("hex");
      const signature = await (() => {
        if (accountToRegister) {
          if (!accountToRegister) {
            // invalid request.  Should have checked for this already:
            throw new Error(_t("backend.internal-error"));
          }
          headers.push(["newPublicKey", accountToRegister]);
          headers.push(["activeUserAddress", accountToRegister]);
          // @ts-ignore
          return window.ethereum.request({
            method: "personal_sign",
            params: [hexMessage2, accountToRegister],
          });
        } else {
          return null;
        }
      })();

      if (signature) {
        headers.push(["signature", signature]);
      }

      headers.push(["familyNames", subjectUserFamilyNames]);
      headers.push(["givenNames", subjectUserGivenNames]);

      if (subjectUserFamilyNames === "")
        throw new Error(_t("register.blank-familynames"));

      if (subjectUserGivenNames === "")
        throw new Error(_t("register.blank-givennames"));

      console.log(headers);

      const newUserResp = await fetch("/private-api/newUser", { headers });
      const newUserJSON = await newUserResp.json();

      if (newUserJSON.success) {
        const { userId, expiry } = newUserJSON;
        setActiveUserFamilyNames(subjectUserFamilyNames.split(/ +/));
        setActiveUserGivenNames(subjectUserGivenNames.split(/ +/));
        setSessionInfo(proposedSessionId, userId, new Date(expiry));
        setUserNumber(userId);
        setLoggedIn(true);
        setConnected(true);
        setActiveUserEthereumAddress(activeUserEthereumAddress_2);
        setSubjectUserFamilyNames("");
        setSubjectUserGivenNames("");
      } else {
        console.error(newUserJSON);
        setErrorMessage(newUserJSON.errorMessage);
      }
    } catch (e) {
      console.log(e);
      // @ts-ignore
      const { code } = e;
      if (code !== 4001 && isStandardException(e)) {
        setErrorMessage(e.message);
      }
    } finally {
      setRegistering(false);
    }
  };

  if (registering) {
    return <div>...</div>;
  }

  return (
    <article id="register-page" className="App-page">
      {/*debugText*/}
      {userNumber === null ? (
        <form className="registration-form">
          <div className="form-line">
            <label>{_t("register.givennames")}</label>
            <input
              ref={givenNamesRef}
              type="text"
              defaultValue={givenNames}
              onChange={(ev) => fieldChanged(ev)}
            />
          </div>
          {givenNamesError && (
            <>
              <br />
              <span className="error">{givenNamesError}</span>
            </>
          )}
          <div className="form-line">
            <label>{_t("register.familynames")}</label>
            <input
              type="text"
              ref={familyNamesRef}
              defaultValue={subjectUserFamilyNames}
              onChange={fieldChanged}
            />
          </div>
          {familyNamesError && (
            <>
              <br />
              <span className="error">{familyNamesError}</span>
            </>
          )}
          <div className="vertically-Arranged">
            <div className="form-line">
              <label>{_t("register.email-address")}</label>

              <input
                ref={emailAddressRef}
                type="email"
                defaultValue={emailAddress}
                onChange={fieldChanged}
              />
            </div>
            {config?.googleClientId && (
              <div>*{_t("register.google-signin-note")}</div>
            )}
          </div>
          <span className="error">{emailAddressError}</span>

          <div className="form-line">
            <label>{_t("register.set-password")}</label>
            <input
              ref={passwordRef}
              type="password"
              defaultValue={password}
              onChange={fieldChanged}
            />
          </div>
          <span className="error">{passwordError}</span>

          <div className="form-line">
            <label>{_t("register.password2")}</label>
            <input
              type="password"
              ref={password2Ref}
              defaultValue={password2}
              onChange={fieldChanged}
            />
          </div>
          <span className="error">{password2Error}</span>

          {requestingAccount
            ? _t("register.requesting-account")
            : hasEthereumWallet && (
                <div className="verticallyArranged">
                  <div>
                    <label>Your Ethereum Address</label>
                  </div>
                  <div>
                    <AddressSelectionList
                      addresses={ethereumAddresses}
                      setAddress={setAccountToRegister}
                      address={accountToRegister ?? "null"}
                    />
                  </div>
                  {ethereumAddresses.length == 0 && (
                    <button onClick={connectMetaMask}>
                      {_t("register.connect-ethereum-address")}
                    </button>
                  )}
                </div>
              )}

          <div className="form-line">
            <button disabled={!ready} onClick={doRegistration}>
              {_t("register.send")}
            </button>
          </div>

          <h3>{_t("g.or")}</h3>

          <button onClick={() => setView("login")}>{_t("g.login")}</button>
        </form>
      ) : (
        <div>
          <h1>{_t("g.done")}</h1>

          <div className="form-line">
            <label htmlFor="registered-userNumber-span">
              {_t("register.userID")}:
            </label>
            <span id="registered-userNumber-span">{userNumber}</span>
          </div>

          <div className="form-line">
            <label>{_t("register.givennames")}:</label>
            {activeUserGivenNames.join(" ")}
          </div>

          <div className="form-line">
            <label>{_t("register.familynames")}:</label>
            {activeUserFamilyNames.join(" ")}
          </div>

          <button onClick={() => setView("projects")}>{_t("g.close")}</button>
        </div>
      )}
    </article>
  );
}
