import * as React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import L from "leaflet";
import { shrinkPhoto } from "../api";
import {
  Config,
  Problem,
  searchProblems,
  getProblemImages,
  OptionalProblem,
} from "../api";
import { isNumber, isStandardException, sleep, ViewType } from "../sdptypes";
import { SubmitProject } from "./submitproject";
import { ErrorBoundary } from "./errorboundary";
import { _t } from "../i18n";

import { Buffer } from "buffer";

import {
  Comodoro_Rivadavia,
  decimalToSexigesimal,
  focusZoom,
  isBoundingBoxStringQuad,
  isPlace,
  maxZoom,
  parseBoundingBoxStringQuad,
  Place,
  wideZoom,
} from "../osm";

interface ProblemListRowProps extends Problem {
  activeUserBitcoincashAddress: null | string;
  changeList: Array<OptionalProblem>;
  currentCity: string;
  defaultImage: string;
  defaultPhotoEncoding: string;
  deleteProblem: (id: number) => void;
  description: string;
  fetchingImages: boolean;
  fetchTimeout: number; //ms
  openProblem: (id: number) => void;
  problemId: number;
  roles: Array<string>;
  selected: boolean;
  sessionId: string;
  setErrorMessage: (msg: string) => void;
  setProblemRow: (p: OptionalProblem) => void;
  setSelectedProblemId: (id: number) => void;
  setShowProjectSubmissionForm: (p: boolean) => void;
  setZoom: (n: number) => void;
  showProjectSubmissionForm: boolean;
  zoom: number;
}

export function ProblemListRow(x: ProblemListRowProps) {
  const {
    activeUserBitcoincashAddress,
    currentCity,
    defaultImage,
    defaultPhotoEncoding,
    fetchingImages,
    fetchTimeout,
    id,
    sessionId,
    setErrorMessage,
    roles,
    setProblemRow,
    deleteProblem,
    selected,
    setSelectedProblemId,
    setShowProjectSubmissionForm,
    setZoom,
    image,
    changeList,
    zoom,
  } = x;

  const [changes, setChanges] = useState<OptionalProblem>({ id });
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formProblemType, setFormProblemType] = useState(x.problemType);
  const [upgradingImage, setUpgradingImage] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState<null | number>(null);
  const listImageRef = useRef(null);

  const EW = `E${_t("problem.west-letter")}`;
  const NS = "NS";

  //if (image) console.log("image:", image.slice(0, 44) + "... ");
  useEffect(() => {
    const thisChangeList = changeList.filter((change) => change.id === id);
    let changeSet: OptionalProblem = { id };
    for (const change of thisChangeList) {
      changeSet = { ...changeSet, ...change };
    }
    setChanges(changeSet);
    setEditMode(Object.keys(changeSet).length > 1);
  }, [changeList]);

  const handleAddressChange = (problemId, event) => {
    try {
      console.log(
        `handling change of address: New value is ${event.target.value}`,
      );
      const newAddress = event.target.value;
      let streetName: string;
      let streetNumber: number;
      let city: string | undefined;
      let changeSet: OptionalProblem = { id: problemId };
      let search =
        newAddress.match(
          /^(?<streetName>\w([\w\s]+)\w) +(?<streetNumber>\d+) *(,(?<city>\w[^,]+))?$/,
        ) ||
        newAddress.match(
          /^(?<streetNumber>\d+) +(?<streetName>\w([\w\s]+)\w) *(,(?<city>\w[^, ]+))?$/,
        ) ||
        newAddress.match(/^(?<streetName>\w[\w\s]+)$/);
      console.log(search?.groups);
      if (search) {
        streetName = search.groups.streetName;
        streetNumber = parseInt(search.groups.streetNumber);
        city = search.city;

        if (streetName && streetName !== x.streetName) {
          changeSet["streetName"] = streetName;
        }

        if (streetNumber && x.streetNumber !== streetNumber) {
          changeSet["streetNumber"] = streetNumber;
        }

        if (city && x.city !== city) {
          changeSet["city"] = city;
        }

        console.log(changeSet);
      } else {
        console.log(`Couldn't parse address: '${newAddress}'`);
        return;
      }

      setChanges(changeSet);
    } catch (e) {
      console.error(e);
      // @ts-ignore
      setErrorMessage(e.message);
    }
  };

  const handleSaveProblem = () => {
    if (saving) {
      return;
    }
    setSaving(true);
    const headers: Array<[string, string]> = [["session_id", sessionId]];
    //console.log(changes);
    for (const key in changes) {
      if (typeof changes[key] === "object" && key === "location") {
        headers.push([
          "location",
          changes[key]?.decLatitude + ", " + changes[key]?.decLongitude,
        ]);
      } else {
        headers.push([key, changes[key]]);
      }
    }

    console.log(headers);
    Promise.any([
      fetch("/private-api/editProblem", {
        headers,
      }),
      sleep(fetchTimeout),
    ])
      .then(async (resp) => {
        if (!resp) {
          throw new Error(_t("g.timeout"));
        }
        const { status } = resp;
        if (status === 404) {
          setErrorMessage("Not yet implemented.");
        } else if (status === 200) {
          resp.json().then((editProblemJSON) => {
            console.log(editProblemJSON);
            setProblemRow({ ...changes });
            setChanges({ id });
          });
        }
      })
      .catch(console.error)
      .finally(() => {
        setSaving(false);
        setEditMode(false);
      });
  };

  const handleDeleteProblem = async () => {
    try {
      const headers: Array<[string, string]> = [
        ["session_id", sessionId],
        ["id", x.id + ""],
      ];

      const deleteResponse = await Promise.any([
        fetch("/private-api/deleteProblem", {
          headers,
        }),
        sleep(fetchTimeout),
      ]);
      if (!deleteResponse) {
        setErrorMessage(_t("g.timeout"));
        return;
      }
      const deleteJSON = await deleteResponse.json();
      const { success, errorMessage } = deleteJSON;
      if (success) {
        try {
          deleteProblem(x.id);
        } catch (e) {
          console.error(e);
          // remake the map?
        }
      } else {
        setErrorMessage(errorMessage);
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      //
    }
  };

  const address = `${x.streetName}${
    x.streetNumber ? x.streetNumber : ""
  }${x.crossStreetName ? "@ " + x.crossStreetName : ""}${
    x.city && x.city !== currentCity ? ", " + x.city : ""
  }`;

  const listImage = listImageRef.current;

  useEffect(() => {
    if (
      upgradingImage ||
      downloadSpeed === null ||
      downloadSpeed < 700 /*kB/s*/
    )
      return () => {};

    setUpgradingImage(true);
    //console.log("checking images");
    try {
      const img = listImage as HTMLImageElement | null;
      if (img) {
        //const style = getComputedStyle( img );
        if (img.naturalHeight < img.height || img.naturalWidth < img.width) {
          //console.log(
          //  `Image dimensions in the page are ${img.width}x${img.height} but the pixels are only ${img.naturalWidth}x${img.naturalHeight}... getting new.`,
          //);
          getProblemImages(
            [id],
            img.naturalHeight * 4 <= 3 * img.naturalWidth
              ? img.width
              : (4 * img.height) / 3,
          )
            .then((betterImageSet) => {
              if (betterImageSet.length) {
                //console.log("upgrading image ", betterImageSet);
                setProblemRow({ id, image: betterImageSet[0].image });
              }
              setUpgradingImage(false);
            })
            .catch((e) => {
              console.error(e);
              setUpgradingImage(false);
            });
        } else {
          console.log(`img is sufficient here`);
          setUpgradingImage(false);
        }
      } else {
        //console.log("img is undefined or null", img);
        setUpgradingImage(false);
      }
    } catch (e) {
      setUpgradingImage(false);
    }
  }, [id, listImage, setUpgradingImage]);

  if (saving)
    return (
      <tr>
        <td colSpan={(roles.includes("admin") ? 1 : 0) + 6}>
          {_t("g.saving")}
        </td>
      </tr>
    );

  const toggleSelect = (ev) => x.openProblem(x.id);

  const handleModification = (field: string, ev) => {
    console.log("handleModification called", field, ev.target.value);
    const newChanges = { ...changes, [field]: ev.target.value };
    setChanges(newChanges);
  };

  const handleProblemTypeChange = (ev) => {
    handleModification("problem-type", ev);
    setFormProblemType(ev.target.value);
  };

  const title = x.title
    .replaceAll("problems.missing sign", _t("problem.missing sign"))
    .replaceAll("problems.damaged sign", _t("problem.damaged sign"))
    .replaceAll("problems.pot hole", _t("problem.pot hole"));

  return (
    <tr
      key={x.id}
      data-problem-id={x.id}
      data-problemid={x.id}
      className={selected ? "selectedRow" : "unSelectedRow"}
    >
      {/* If the structure here is modified scrollToSelectedProblem, and scrollToProblem may need to be modified or rewritten
         so that scrolling to a problem will work. */}
      {x.roles.includes("admin") && (
        <td>
          <span
            onClick={handleDeleteProblem}
            className="fa fa-trash wallbreathe"
          />
        </td>
      )}

      {
        <td>
          <div className="image-over-text-container">
            <div
              className="text-over-image"
              onClick={editMode ? () => 0 : toggleSelect}
            >
              {!x.image && fetchingImages ? (
                <div className="fa fa-photo" />
              ) : x.image &&
                x.image !== `${defaultPhotoEncoding},${defaultImage}` ? (
                <img
                  className="listImage"
                  alt="problem"
                  src={x.image}
                  ref={listImageRef}
                />
              ) : (
                <div className="fa fa-photo" />
              )}
            </div>
            {editMode ? (
              <input
                type="text"
                onChange={(ev) => handleModification("title", ev)}
                disabled={!x.roles.includes("admin")}
                defaultValue={x.title}
              />
            ) : (
              <div className="text-under-image">{title}</div>
            )}
          </div>
        </td>
      }
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <select onChange={handleProblemTypeChange}>
            <option
              value="pot hole"
              selected={formProblemType === "pot hole" || undefined}
            >
              {_t("problem.pot hole")}
            </option>
            <option
              value="damaged sign"
              selected={formProblemType === "damaged sign" || undefined}
            >
              {_t("problem.damaged sign")}
            </option>
            <option
              value="missing sign"
              selected={formProblemType === "missing sign" || undefined}
            >
              {_t("problem.missing sign")}
            </option>
          </select>
        ) : (
          _t("problem." + formProblemType)
        )}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <input
            type="text"
            disabled={!x.roles.includes("admin")}
            defaultValue={address}
            onChange={(ev) => handleAddressChange(x.id, ev)}
          />
        ) : (
          <span>{address}</span>
        )}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {x?.location?.decLatitude &&
          (editMode ? (
            <input
              type="text"
              defaultValue={
                (x?.location?.decLatitude &&
                  decimalToSexigesimal(x.location.decLatitude, NS)) +
                " " +
                (x?.location?.decLongitude &&
                  decimalToSexigesimal(x.location.decLongitude, EW))
              }
            />
          ) : (
            <span>
              {decimalToSexigesimal(x?.location?.decLatitude, NS) +
                " " +
                decimalToSexigesimal(x?.location?.decLongitude, EW)}
            </span>
          ))}
      </td>
      <td className="wide" onClick={editMode ? () => 0 : toggleSelect}>
        {editMode ? (
          <textarea
            defaultValue={x.body ?? ""}
            onChange={(ev) => handleModification("body", ev)}
          />
        ) : (
          <span>{x.body}</span>
        )}
      </td>
      {x.roles.includes("provider") &&
        activeUserBitcoincashAddress &&
        activeUserBitcoincashAddress > "" && (
          <td>
            <span
              className="fa fa-plus"
              onClick={() => {
                setSelectedProblemId(x.id);
                setShowProjectSubmissionForm(true);
              }}
            >
              {_t("problem.new-project")}
            </span>
          </td>
        )}
      {x.roles.includes("admin") && (
        <td className="wide">
          {editMode || (
            <span onClick={() => setEditMode(true)} className="fa fa-pencil" />
          )}
          {editMode && (
            <span onClick={() => setEditMode(false)} className="fa fa-times" />
          )}
          {editMode && Object.keys(changes).length > 0 && (
            <span onClick={handleSaveProblem} className="fa fa-save" />
          )}
        </td>
      )}
    </tr>
  );
}
