import { useState, useCallback, useEffect, useRef } from "react";
import L from "leaflet";
import { shrinkPhoto } from "../api";
// @gus-include problem.pot-hole
// @gus-include problem.damaged sign
// @gus-include problem.missing sign
import { ProblemMap } from "./problem-map";

import { Buffer } from "buffer";

import {
  Comodoro_Rivadavia,
  decimalToSexigesimal,
  focusZoom,
  isBoundingBoxStringQuad,
  isPlace,
  maxZoom,
  parseBoundingBoxStringQuad,
  Place,
  wideZoom,
} from "../osm";
import * as ls from "../local-storage";

import {
  Config,
  Problem,
  searchProblems,
  getProblemImages,
  OptionalProblem,
} from "../api";
import { isNumber, isStandardException, sleep, ViewType } from "../sdptypes";
import { SubmitProject } from "./submitproject";
import { ErrorBoundary } from "./errorboundary";
import { _t } from "../i18n";
import { ProblemListRow } from "./problem-list-row";
type Timeout = ReturnType<typeof setTimeout>;
type LeafLetType = any;

interface ProblemListProps {
  activeUserEthereumAddress: null | string;
  activeUserBitcoincashAddress: null | string;
  activeUserNumber: null | number;
  cities: { [id: string]: Place };
  config: Config;
  currentCity: string;
  defaultPhotoEncoding: string;
  defaultImage: string;
  deleteProblem: any;
  fetchTimeout: number; //ms
  language: string;
  loadedProblems: boolean;
  oSMEnabled: boolean;
  problemSet: Array<Problem>;
  roles: Array<string>;
  selectedProblemId: null | number;
  sessionId: string;
  setActiveUserEthereumAddress: (a: string) => void;
  setDownloadSpeed: (v: number) => void;
  setErrorMessage: (m: string) => void;
  setLoadedProblems: (b: boolean) => void;
  setProblemSet: (a: Array<Problem>) => void;
  setSelectedProblemId: (n: number | null) => void;
  setProblemListRef: (ref: { current: any }) => void;
  setProblemTableHeaderRef: (ref: { current: any }) => void;
  setProblemTableBodyRef: (ref: { current: any }) => void;
  setProblemTableRef: (ref: { current: any }) => void;
  setShowProjectSubmissionForm: (b: boolean) => void;
  setZoom: (n: number) => void;
  showProjectSubmissionForm: boolean;
  style?: { [id: string]: any };
  view: ViewType;
  websiteName: string;
  zoom: null | number;
}

export function ProblemList(props: ProblemListProps): JSX.Element {
  const {
    activeUserBitcoincashAddress,
    activeUserEthereumAddress,
    activeUserNumber,
    cities,
    config,
    currentCity,
    defaultImage,
    defaultPhotoEncoding,
    deleteProblem,
    fetchTimeout,
    language,
    loadedProblems,
    problemSet,
    roles,
    selectedProblemId,
    sessionId,
    setDownloadSpeed,
    setErrorMessage,
    setLoadedProblems,
    setProblemSet,
    setSelectedProblemId,
    setShowProjectSubmissionForm,
    setZoom,
    showProjectSubmissionForm,
    websiteName,
    zoom,
  } = props;
  const [loadingProblems, setLoadingProblems] = useState(false);
  const [cityInfo, setCityInfo] = useState<Place>(
    cities[currentCity] ?? Comodoro_Rivadavia,
  );
  const [citySearch, onlySetCitySearch] = useState(currentCity);
  const [allProblemPhotosLoaded, setAllProblemPhotosLoaded] = useState(false);

  const setCitySearch = (pCityName: string) => {
    // This is a hard coded array, so it must be either undefined or perfect.
    if (!Object.keys(cities).includes(pCityName)) {
      if (cityTypeTimeout.current) clearTimeout(cityTypeTimeout.current);
      cityTypeTimeout.current = setTimeout(() => getNewCity(pCityName), 2000);
      return;
    }
    const maybeCityRecord: undefined | Place = cities[pCityName];
    if (maybeCityRecord && isPlace(maybeCityRecord)) {
      const cityRecord: Place = maybeCityRecord as Place;
      // cityRecord,boundingBox is a BoundsStringArray.
      ls.set("city", pCityName);
      if (isBoundingBoxStringQuad(cityRecord.boundingbox)) {
        onlySetCitySearch(pCityName);
        setCityInfo(cityRecord);
      } else {
        console.log("city info record is bad:", cityRecord);
        setCityInfo(Comodoro_Rivadavia);
      }
    } else {
      setCityInfo(Comodoro_Rivadavia);
    }
  };

  const [problemsType, onlySetProblemsType] = useState<
    "any" | "damaged sign" | "missing sign"
  >("any");

  const [needsToLoadImages, onlySetNeedsToLoadImages] = useState(0);
  const setNeedsToLoadImages = () => {
    // console.log(`called: needsToLoadImages`);
    onlySetNeedsToLoadImages((k) => k + 1);
  };
  const [fetchingImages, setFetchingImages] = useState(false);
  const [changeList, setChangeList] = useState<any>([]);

  const cityTypeTimeout = useRef<Timeout | null>(null);
  const mapLeafletRef = useRef<LeafLetType>(null);
  const problemTableHeaderRef = useRef<HTMLTableRowElement>(null);
  const problemTableBodyRef = useRef<HTMLTableSectionElement>(null);
  const problemTableRef = useRef<HTMLTableElement>(null);
  const problemListRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<{ [id: string]: L.Icon }>({});

  useEffect(() => {
    if (!loadedProblems) {
      console.log("Loading problems due to a use Effect call");
      loadProblems(problemsType).then((failureString) => {
        setAllProblemPhotosLoaded(false);
        setLoadedProblems(failureString === null || failureString === "");
        console.log(failureString);
      });
      setNeedsToLoadImages();
    }
  }, [config]);

  const scrollToProblem = (id) => {
    if (
      !problemListRef.current ||
      !problemTableRef.current ||
      !problemTableHeaderRef.current ||
      !problemTableBodyRef.current ||
      !problemTableBodyRef?.current?.firstChild
    )
      return;
    console.log({ problemTable: problemTableRef.current });
    let scrollAmount = problemTableHeaderRef.current.clientHeight;
    console.log(scrollAmount);

    for (
      let tableRowElement: ChildNode | null | undefined =
        problemTableBodyRef?.current?.firstChild;
      !!tableRowElement;
      tableRowElement = tableRowElement.nextSibling
    ) {
      const thisProblemId: string | undefined =
        // @ts-ignore
        tableRowElement?.dataset?.problemid;
      if (!thisProblemId) break;

      if (parseInt(thisProblemId) === id) {
        console.log("returning...scrolling to ", scrollAmount);
        problemListRef.current.scrollTo({
          top: scrollAmount,
          behavior: "smooth",
        });

        return;
      }
      // @ts-ignore
      const clientHeight = tableRowElement?.clientHeight;
      if (!clientHeight || !isNumber(clientHeight)) break;
      scrollAmount += clientHeight;
    }
    console.error(
      "Failure to scroll due to an inability to find the problemId.",
      id,
    );
  };

  async function getNewCity(cityNameP: string): Promise<any> {
    if (!cityNameP)
      return new Promise<any>((result, error) => {
        result([]);
      });
    if (!Object.keys(cities).includes(cityNameP))
      new Promise<any>((result, error) => {
        result(cities[cityNameP]);
      });

    return Promise.any([
      fetch(
        `https://nominatim.openstreetmap.org/search?` +
          `format=json&q=${cityNameP}, Chubut, Argentina`,
      ),
      sleep(fetchTimeout),
    ])
      .then((rawResponse) => {
        if (!rawResponse) {
          setErrorMessage(_t("g.timeout"));
          return [];
        }
        if (rawResponse.ok) {
          rawResponse.json().then((response) => {
            console.log(cityNameP, response);
            const thisCity = response.find((x) =>
              ["city", "town", "village"].includes(x.addresstype),
            );
            if (thisCity) {
              onlySetCitySearch(thisCity.name);
              cities[cityNameP] = thisCity;
              setCityInfo(thisCity);
            }
          });
        }
      })
      .catch(console.error);
  }

  useEffect(() => {
    if (!mapLeafletRef.current) return () => {};
    const handleSelectedProblemIdChange = () => {
      if (selectedProblemId === null) {
        mapLeafletRef.current.setZoom(zoom ?? wideZoom);
      } else {
        const problem = problemSet.find((p) => p.id === selectedProblemId);
        if (problem && mapLeafletRef.current) {
          const { location } = problem;
          if (location) {
            const { decLatitude, decLongitude } = location;
            if (!isNaN(decLatitude) && !isNaN(decLongitude)) {
              mapLeafletRef.current.setView(
                [decLatitude, decLongitude],
                focusZoom,
              );
            }
          }
        }
      }
    };
    handleSelectedProblemIdChange();
    return () => {};
  }, [selectedProblemId, problemSet, zoom]);

  useEffect(() => {
    const potholeIcon = L.icon({
      iconUrl: "./pot-hole.png",
      iconSize: [70, 42],
      iconAnchor: [35, 21],
      popupAnchor: [0, -21],
    });

    const brokenSignIcon = L.icon({
      iconUrl: "./broken-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    const missingSignIcon = L.icon({
      iconUrl: "./missing-sign.png",
      iconSize: [70, 42],
      iconAnchor: [35, 41],
      popupAnchor: [0, -21],
    });

    iconRef.current["pot hole"] = potholeIcon;
    iconRef.current["damaged sign"] = brokenSignIcon;
    iconRef.current["missing sign"] = missingSignIcon;

    return () => {
      // should remove?
      // iconRef.current["pot hole"].remove();
    };
  }, []);

  useEffect(() => {
    if (
      !iconRef.current ||
      !iconRef.current["pot hole"] ||
      !iconRef.current["damaged sign"] ||
      !iconRef.current["missing sign"]
    ) {
      return;
    }
    for (const problem of problemSet) {
      if (problem.marker) {
        const { marker, problemType } = problem;
        if (
          marker &&
          iconRef.current[problemType] // should always be true
        ) {
          marker.setIcon(iconRef.current[problemType]);
        } else {
          console.error("no marker or invalid problemType settting up problem");
        }
      } // if
    } // for
  }, [problemSet, iconRef.current]);
  const getTime = () => new Date().getTime();

  useEffect(() => {
    // console.log(
    //   "fetchingImages:",
    //   fetchingImages,
    //   "displayProblemImages:",
    //   config?.displayProblemImages,
    // );
    if (fetchingImages || !config || !config.displayProblemImages)
      return () => {};

    (async () => {
      try {
        setFetchingImages(true);
        if (problemSet.length >= 2) {
          const firstProblem = problemSet[0];
          const secondProblem = problemSet[1];
          if (!firstProblem.image || !secondProblem.image) {
            const t0 = getTime();
            const imageStructs = await getProblemImages([
              firstProblem.id,
              secondProblem.id,
            ]);
            const t1 = getTime();
            const size =
              imageStructs[0].image.length + imageStructs[1].image.length;
            console.log("setting download speed... ", size / (t1 - t0));
            setDownloadSpeed(size / (t1 - t0));
            handleProblemsWithImages(imageStructs);
          }
        } // if
        // image less problem id set.
        const idSet = (
          problemSet.length >= 2 ? problemSet.slice(2) : problemSet
        )
          .filter((p) => !p.image)
          .map((p) => p.id);
        for (let i = 0; i < idSet.length; i += 5) {
          const t0 = getTime();
          const imageStructs = await getProblemImages(
            idSet.slice(i, Math.min(i + 5, idSet.length)),
          );
          const t1 = getTime();
          console.log(
            "New speed:",
            imageStructs
              .map((is) => is.image.length)
              .reduce((a, b) => a + b, 0) /
              (t1 - t0),
          );
          handleProblemsWithImages(imageStructs);
        } // for
        setAllProblemPhotosLoaded(true);
      } catch (ex) {
        setNeedsToLoadImages();
      } finally {
        setFetchingImages(false);
      }
    })();
    return () => {};
  });

  const handleProblemsWithImages = (imageStructs) => {
    const problemsWithMarkers = problemSet;
    // console.log(
    //   "handling problems with images",
    //   imageStructs.map((p) => p.id),
    // );
    let imageHash: { [id: number]: Problem } = {};
    if (
      JSON.stringify(imageStructs.map((p) => p.id)) ===
      JSON.stringify(problemsWithMarkers.map((p) => p.id))
    ) {
      console.log("Order is preserved.  Does this happen alot?");
    }

    const renderInMap = (p, image) => {
      //  obsolete. done in an effect.
      // See: @renderInMapEffect
    };

    try {
      let errorFlag = false;
      let alteredProblem = false;
      let imagesToAdd = imageStructs.length;
      for (const image of imageStructs) {
        imageHash[image.id] = image;
      }
      problemSet.forEach((p) => {
        ((param: string) => {
          const byteaBuffer = Buffer.from(param);
          const bigImage = byteaBuffer.toString("utf-8");

          if (bigImage === "") {
            setNeedsToLoadImages();
          } else if (bigImage.length < 2e5) {
            const newProblemSet = [...problemSet];
            const thisProblem = newProblemSet.find((q) => q.id === p.id);
            if (thisProblem) {
              thisProblem.image = bigImage;
              setProblemSet(newProblemSet);
              setFetchingImages(--imagesToAdd > 0);
              renderInMap(p, bigImage);
            } else {
              // deleted problem while rendering: abandon this!
              setFetchingImages(false);
            }
          } else {
            alteredProblem = true;
            shrinkPhoto(bigImage, 800).then((imageStruct) => {
              const { url, width, height } = imageStruct;
              const id = p.id;
              console.log({
                id,
                width,
                height,
                image: url.slice(0, 24) + "...",
              });
              const image = url;
              const newProblemSet = [...problemSet];
              const thisProblem = newProblemSet.find((q) => q.id == id);
              if (thisProblem) {
                thisProblem.image = image;
                setProblemSet(newProblemSet);
                setFetchingImages(--imagesToAdd > 0);
                renderInMap(p, image);
              } else {
                // deleted problem while rendering: abandon this!
                setFetchingImages(false);
                console.log(
                  " update failed because entry is missing.  Deleted?",
                );
              }
            });
          }
        })(imageHash[p.id]?.image ?? "");
      });
    } finally {
    }
  };

  // returns a promise to a string which will be an empty if successful or
  // an English language message indicating the nature of the failure.
  const loadProblems = useCallback(
    async (problemType: string): Promise<string> => {
      if (loadingProblems) {
        console.log("Not loadingProblems because ", { loadingProblems });
        return "already loading";
      }
      try {
        let problems: void | Array<Problem>;
        setLoadingProblems(true);
        problems = await Promise.any([
          searchProblems(problemType, citySearch, language, sessionId),
          sleep(fetchTimeout),
        ]);
        if (!!problems) {
          for (
            let problem_index = 0;
            problem_index < problems.length;
            ++problem_index
          ) {
            const problem = problems[problem_index];
            //console.log(problem);
            const { id, title, location, body, problemType } = problem;
            const { decLatitude, decLongitude } = location;
            const markerIcon = iconRef?.current[problemType];
            //console.log(markerIcon);
            //console.log(`icon: `, JSON.stringify(markerIcon));
            const marker = L.marker([decLatitude, decLongitude], {
              icon: markerIcon,
              opacity: problemType === "pot hole" ? 0.5 : 1,
              draggable: roles.includes("admin"),
            });
            marker.on("click", (ev) => {
              marker.openTooltip([decLatitude, decLongitude]);
              setSelectedProblemId(id);
              scrollToProblem(id);
            });
            marker.on("dragend", (ev) => {
              console.log(ev);
              const { target } = ev;
              if (target) {
                const { _latlng } = target;
                if (_latlng && isNumber(_latlng.lat) && isNumber(_latlng.lng)) {
                  if (problems) {
                    const first = problems.slice(0, problem_index);
                    const second = problems.slice(problem_index + 1);
                    let location = {
                      decLatitude: _latlng.lat,
                      decLongitude: _latlng.lng,
                    };
                    setProblemSet([
                      ...first,
                      {
                        ...problem,
                        location,
                      },
                      ...second,
                    ]);
                    setChangeList([...changeList, { id, location }]);
                  } // if
                } // if
              } // if
              scrollToProblem(id);
            });
            if (problem.marker && problem.marker.off) {
              problem.marker.off();
            }
            problem.marker = marker;
          } // for problem
          setProblemSet(problems);
          return "";
        } // if problems
      } catch (e) {
        console.error(e);
        setErrorMessage(_t("problem.database-update-failure"));
        return "some-exception";
      } finally {
        setNeedsToLoadImages();
        setLoadingProblems(false);
      }
      return "timeout error";
    },
    [setErrorMessage, setProblemSet, citySearch, fetchTimeout],
  );

  const setProblemsType = useCallback(
    (newValue) => {
      console.log("Loading problems due to a setProblemsType call");
      if (loadingProblems) {
        // deny!
        return;
      }
      onlySetProblemsType(newValue);
      loadProblems(newValue);
    },
    [loadingProblems, loadProblems, onlySetProblemsType],
  );

  const openProblem = (id: number) => {
    if (selectedProblemId === id) {
      document.title = websiteName;
      setSelectedProblemId(null);
    } else {
      const problem = problemSet.find((x) => x.id === id);
      if (problem) {
        document.title = problem.title;
      }
      setSelectedProblemId(id);
    }
  };

  if (loadingProblems) return <span>{_t("g.loading")}</span>;

  if (!loadedProblems)
    return (
      <button onClick={(ev) => loadProblems("any")}>{_t("g.try-again")}</button>
    );

  const setProblemRow = (problemChange: OptionalProblem) => {
    let problemIndex = -1;
    //console.log(`setProblemRow(`, problemChange, `...)`);
    let oldProblem;
    for (let i = 0; i <= problemSet.length; ++i) {
      if (problemSet[i].id === problemChange.id) {
        problemIndex = i;
        oldProblem = problemSet[i];
        break;
      }
    }

    if (problemIndex === -1) {
      console.error("Problem not found!");
      return;
    }

    const modifiedProblem = { ...oldProblem, ...problemChange };

    setProblemSet([
      ...problemSet.slice(0, problemIndex),
      modifiedProblem,
      ...problemSet.slice(problemIndex + 1),
    ]);
  };

  return !!showProjectSubmissionForm ? (
    <ErrorBoundary fallback={_t("problem.project-submission-disabled")}>
      <div />
      <SubmitProject
        activeUserBitcoinCashAddress={activeUserBitcoincashAddress ?? ""}
        activeUserNumber={activeUserNumber}
        activeUserEthereumAddress={activeUserEthereumAddress}
        config={config}
        problemSet={problemSet}
        sessionId={sessionId}
        setErrorMessage={setErrorMessage}
        setShowProjectSubmissionForm={setShowProjectSubmissionForm}
        selectedProblemId={selectedProblemId}
        setSelectedProblemId={setSelectedProblemId}
      />
    </ErrorBoundary>
  ) : (
    <div id="problemSection" style={props.style}>
      <div id="leftBrainProblemSection">
        <div className="horizontallyArranged">
          <div className="form-line">
            <label>{_t("problem.type")}</label>
            <select
              name="search-type"
              id="search-type"
              defaultValue={problemsType}
              onChange={(ev) => setProblemsType(ev.target.value)}
            >
              <option value="any">{_t("problem.any")}</option>
              <option value="damaged sign">{_t("problem.damaged sign")}</option>
              <option value="pot hole">{_t("problem.pot hole")}</option>
              <option value="missing sign">{_t("problem.missing sign")}</option>
            </select>
          </div>
          <div className="form-line">
            <label>{_t("problem.city")}</label>
            <input
              type="text"
              className="cityName"
              list="cities"
              placeholder={_t("problem.city")}
              onChange={(ev) => setCitySearch(ev.target.value)}
              defaultValue={citySearch}
            />
            <datalist id="cities">
              {Object.keys(cities).map((name) => (
                <option value={name} key={name} />
              ))}
            </datalist>
          </div>
        </div>
        {problemSet.length === 0 ? (
          <h1>{_t("problem.none")}</h1>
        ) : (
          <div id="problemList" ref={problemListRef}>
            <table id="problemTable" ref={problemTableRef}>
              <thead>
                <tr ref={problemTableHeaderRef} id="problemTableHeader">
                  {roles.includes("admin") && <th />}
                  <th>{_t("problem.title")}</th>

                  <th className="wide">{_t("problem.type")}</th>

                  <th className="wide">{_t("problem.address")}</th>

                  <th className="wide">{_t("problem.coordinates")}</th>

                  <th className="wide">{_t("problem.description")}</th>

                  {roles.includes("provider") && <th />}

                  {roles.includes("admin") && <th />}
                </tr>
              </thead>
              <tbody ref={problemTableBodyRef} id="problemTableBody">
                {problemSet.map(
                  (x) =>
                    x && (
                      <ProblemListRow
                        activeUserBitcoincashAddress={
                          activeUserBitcoincashAddress
                        }
                        changeList={changeList}
                        defaultPhotoEncoding={defaultPhotoEncoding}
                        defaultImage={defaultImage}
                        problemId={x.id}
                        key={x.id}
                        selected={selectedProblemId === x.id}
                        currentCity={currentCity}
                        fetchingImages={fetchingImages}
                        fetchTimeout={fetchTimeout}
                        setErrorMessage={setErrorMessage}
                        openProblem={openProblem}
                        setProblemRow={setProblemRow}
                        sessionId={sessionId}
                        setSelectedProblemId={setSelectedProblemId}
                        setShowProjectSubmissionForm={
                          setShowProjectSubmissionForm
                        }
                        setZoom={setZoom}
                        showProjectSubmissionForm={showProjectSubmissionForm}
                        roles={roles}
                        description={x.body ?? ""}
                        {...x}
                        deleteProblem={deleteProblem}
                        zoom={zoom ?? 0}
                      />
                    ),
                )}
                {
                  null /*The following blank row is to make the last element always visible in all browsers*/
                }
                <tr id="problemListlastTableRow">
                  <td id="problemListlastTableCell">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ProblemMap
        mapId="problemMap"
        style={{}}
        title={websiteName}
        cityDecimalLatitude={cityInfo?.lat}
        cityDecimalLongitude={cityInfo?.lon}
        problemSet={problemSet}
        place={cityInfo}
        setProblemSet={setProblemSet}
        setSelectedProblemId={setSelectedProblemId}
        setZoom={setZoom}
        scrollToProblem={scrollToProblem}
        selectedProblemId={selectedProblemId}
        zoom={zoom}
      />
    </div>
  );
} // function
