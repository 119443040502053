import { useState } from "react";
import { ProblemTableRow, UserTableRow } from "./database";

import L from "leaflet";
import {
  FailedGeneralResponse,
  isDate,
  sleep,
  SuccessfulGeneralResponse,
} from "./sdptypes";

export interface SuccessfulNewProjectResponse
  extends SuccessfulGeneralResponse {
  errorMessage: null;
  projectId: number;
}

export interface Problem {
  id: number;
  title: string;
  problemType: string;
  body: string | null;
  city: string;
  streetName: string | null;
  streetNumber: number | null;
  crossStreetName: string | null;
  suburb: string | null;
  location: { decLatitude: number; decLongitude: number };
  image: null | string;
  marker?: L.Marker;
  projects: number[];
}

type NoIdProblem = Omit<Problem, "id">;

// This is the same as problem but only id is required and all others are
// optonal.
export type OptionalProblem = Partial<NoIdProblem> & { id: number };

export interface CurrencyBlockchain {
  currencyId: string;
  currencyName: string;
  blockchainId: string;
  blockchainDisplayName: string;
  contractAddress: Buffer | null;
  decimalPlaces: number;
  testnet: boolean;
}

export interface Config {
  backendRunning: null | boolean;
  bitcoin: "bitcoincash" | "bchtestnet" | "bchregtest" | "hidden";
  bitcoinFundingStep: boolean;
  confuse: boolean;
  currencyBlockchains: Array<CurrencyBlockchain>;
  displayProblemImages: boolean;
  git: null | string;
  googleClientId: string;
  hg: null | string;
  loginsAllowed: boolean;
  payAddress: string;
  testnet: boolean;
  websiteName: string;
}

const nullConfig: Config = {
  backendRunning: null,
  bitcoin: "hidden",
  bitcoinFundingStep: false,
  confuse: false,
  currencyBlockchains: [],
  displayProblemImages: false,
  git: null,
  googleClientId: "",
  hg: null,
  loginsAllowed: false,
  payAddress: "",
  testnet: true,
  websiteName: "",
};

export function getConfig(): Promise<Config> {
  return Promise.any([fetch("/private-api/getConfig"), sleep(10_000)]).then(
    (resp) => {
      if (!resp) {
        console.error("timeout");
        return { ...nullConfig, backendRunning: false };
      } else if (resp.ok) {
        return resp
          .json()
          .then((jSON) => {
            return { ...jSON, backendRunning: true };
          })
          .catch((e) => {
            console.error("Exception parsing JSON", e);
            return { ...nullConfig, backendRunning: true };
          });
      }
      console.error("resp.ok is false");
      return { ...nullConfig, backendRunning: false };
    },
  );
}

interface getProblemsResponse {
  success: boolean;
  list?: Array<ProblemTableRow>;
  errorMessage?: null;
}

function databaseProblemToApiProblem(x: ProblemTableRow): Problem {
  let projects: any = x.projects;
  try {
    projects = JSON.parse(JSON.parse(projects));
  } catch (e) {
    console.error(e);
    projects = [];
  }

  return {
    id: parseInt(x.id),
    title: x.title,
    problemType: x.problem_type,
    // @ts-ignore
    problem_type: x.problem_type,
    body: x.body,
    city: x.city || "",
    streetName: x.street_name,
    streetNumber: (x.street_number && parseInt(x.street_number)) || null,
    crossStreetName: x.cross_street_name,
    suburb: x.suburb,
    location: { decLongitude: x.location.y, decLatitude: x.location.x },
    image: x.image,
    projects: projects,
  };
}

export async function searchProblems(
  problemType: string,
  cityName: string,
  language: string,
  session_id: string,
): Promise<Array<Problem>> {
  const resp = await fetch("/private-api/searchProblems", {
    headers: {
      problemType: problemType,
      session_id,
      language,
      cityName,
      "Content-Type": "text/json",
    },
  });
  if (!resp.ok) {
    throw new Error("Cannot load problems");
  }
  const jSON = await (resp.json() as Promise<getProblemsResponse>);
  const { errorMessage, success, list } = jSON;
  if (!success) {
    //console.log(jSON);
  }
  if (errorMessage) {
    throw new Error(errorMessage);
  }
  if (!list) {
    return [];
  }
  return list.map(databaseProblemToApiProblem);
}

export interface ImageDetail {
  id: number;
  width: number;
  height: number;
  image: string;
}

type GetProblemImagesResponse = Array<ImageDetail>;

interface GetProblemImagesServerResponse {
  success: boolean;
  results: GetProblemImagesResponse;
  errorMessage;
}

export async function getProblemImages(
  ids: Array<number>,
  minWidth: undefined | number = 0,
): Promise<GetProblemImagesResponse> {
  const idsString = ids.map((id) => String(id)).join(",");

  const headers = new Headers(
    minWidth
      ? {
          ids: idsString,
          minWidth: minWidth.toString(),
          "Content-Type": "text/json",
        }
      : {
          ids: idsString,
          "Content-Type": "text/json",
        },
  );

  const resp = await Promise.any([
    fetch("/private-api/getProblemImages", {
      headers: headers,
    }),
    sleep(6000),
  ]);

  if (!resp) {
    throw new Error("login.error-timeout");
  }

  if (!resp.ok) {
    console.error(resp);
    throw new Error("login.error-user-fetch");
  }

  const jSON = (await resp.json()) as GetProblemImagesServerResponse;

  if (jSON.success === false) {
    console.error(jSON);
    throw new Error(jSON.errorMessage);
  }

  return jSON.results;
}

type NewProjectResponse = FailedGeneralResponse | SuccessfulNewProjectResponse;

export async function newProject(
  sessionId: string,
  provider_id: number,
  problemId: number,
  currency: string,
  blockchain: string,
  prepay: number,
  postpay: number,
  funding_deadline: Date,
  work_deadline: Date,
  body: string,
  gas_price: number,
  value: number,
  transactionString: string,
): Promise<NewProjectResponse> {
  const TwentyFourHours = 24 * 60 * 60 * 1000;
  const tomorrow = new Date(new Date().getTime() + TwentyFourHours);

  const payment_deadline = new Date(
    work_deadline.getTime() + 7 * TwentyFourHours,
  );

  const headers = {
    session_id: sessionId,
    provider_id: String(provider_id),
    problem_id: String(problemId),
    currency_id: currency,
    blockchain_id: blockchain,
    prepay_goal: String(prepay),
    postpay_goal: String(postpay),
    acceptance_deadline: tomorrow.toISOString(),
    funding_deadline: funding_deadline.toISOString(),
    work_deadline: work_deadline.toISOString(),
    payment_deadline: payment_deadline.toISOString(),
    body: body ?? ".",
    gas_price: (gas_price * 1e18).toString(16),
    value: (value * 1e18).toString(16),
  };

  const resp: Response = await fetch("/private-api/newProject", {
    method: "POST",
    headers: headers,
    body: transactionString,
  });

  if (!resp.ok) {
    throw new Error("problem.new-project-error-fetch");
  }

  const jtSON = (await resp.json()) as any;
  let projectId: number | undefined | string = jtSON.projectId as
    | undefined
    | string;
  if (projectId) {
    projectId = parseInt(projectId);
  }
  const jSON = { ...jtSON, projectId } as NewProjectResponse;

  if (!jSON.success) {
    console.error(jSON.errorMessage);
    console.error(headers);
    throw new Error("problem.new-project-error-read");
  }

  return jSON;
}

//  large       big    small     tiny     run
//  ok          ok       ok       ok      eww
// 400x225     267x150 200x122  133x75  100x56  80x45
// 23kB-75kB    35kB    7.5kB   5000B

// 3x4 is far more common:
// 360x270

export async function shrinkPhoto(
  originalImage: string,
  maximumDimmension: number,
) {
  // reduce the file size of the photo uploaded for storage.
  let defaultRet = { url: originalImage, width: 0, height: 0 };
  try {
    const createImage = (src) => {
      const img = document.createElement("img") as HTMLImageElement;
      const p = new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = () => {
          resolve(img);
        };
        img.onerror = (e) => {
          reject(null);
        };
      });
      img.src = src;
      return p;
    };

    const img = await createImage(originalImage);
    defaultRet = { url: originalImage, width: img.width, height: img.height };
    if (img) {
      if (
        (img.width <= maximumDimmension && img.height <= maximumDimmension) ||
        img.width * img.height === 0
      ) {
        return defaultRet;
      }
      const canvas = document.createElement("canvas");
      let scale_factor = 1;
      if (img.width >= img.height) {
        // the image is landscapeish
        canvas.height = Math.floor(
          (maximumDimmension * img.height) / img.width,
        );
        canvas.width = maximumDimmension;
        scale_factor = maximumDimmension / img.width;
      } else {
        // the image is portraitish
        canvas.height = maximumDimmension;
        canvas.width = Math.floor((img.width * maximumDimmension) / img.height);
        scale_factor = maximumDimmension / img.height;
      }
      const ctx = canvas.getContext("2d");
      if (ctx === null) {
        console.error("ctx null here");
        return defaultRet;
      }
      ctx.scale(scale_factor, scale_factor);
      const imageBitmap = await createImageBitmap(img);
      ctx.drawImage(imageBitmap, 0, 0, img.width, img.height);
      return {
        url: canvas.toDataURL(),
        width: canvas.width,
        height: canvas.height,
      };
    }
  } catch (e) {
    // cannot resize photo
    console.error("Cannot resize photo", e);
  }
  return defaultRet;
}

var objIdMap = new WeakMap(),
  objectCount = 0;
function objectId(object) {
  if (!objIdMap.has(object)) objIdMap.set(object, ++objectCount);
  return objIdMap.get(object);
}

export function setCookie(
  cname: string,
  cvalue: string,
  expiryMilliSecondsOrExpiry: number | Date,
) {
  if (cname.indexOf(";") != -1) {
    throw new Error(`Invalid cookie name: ${cname}`);
  }

  const d: Date = (() => {
    if (isDate(expiryMilliSecondsOrExpiry)) {
      return expiryMilliSecondsOrExpiry;
    } else {
      let d = new Date();
      d.setTime(d.getTime() + expiryMilliSecondsOrExpiry);
      return d;
    }
  })();
  var expires = d.toUTCString();
  if (expires === "Invalid Date") {
    console.error(
      "invalid date or duration:  set to",
      expiryMilliSecondsOrExpiry,
    );
    throw new Error(
      "invalid date or duration:  set to" + expiryMilliSecondsOrExpiry,
    );
  }
  var cookieCommand =
    cname +
    "=" +
    encodeURIComponent(JSON.stringify(cvalue)) +
    ";expires=" +
    expires +
    ";path=/";
  //console.log(cookieCommand);
  document.cookie = cookieCommand;
}

export function getCookie(cname): null | string | object {
  let name = cname + "=";
  let cookieArray = document.cookie
    .split(";")
    .map((sub) => decodeURIComponent(sub));
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      const ret = c.substring(name.length, c.length);
      try {
        return JSON.parse(ret);
      } catch (e) {
        return ret;
      }
    }
  }
  return null;
}

export const oneCentury = 100 * 365.25 * 24 * 3600 * 1000; /*ms*/
export const oneHour = 3600 * 1000; /*ms*/

export interface UserInformation {
  id: number;
  givenNames: string;
  familyNames: string;
  admin: boolean;
  provider: boolean;
  donor: boolean;
  publicKeys: Array<string>;
  bitcoinCashAddress: string;
  hasPassword: boolean;
}

interface GetUserListResponse {
  success: boolean;
  errorMessage?: string;
  data?: UserTableRow[];
}

export async function getUsersList(session_id: string): Promise<Response> {
  return fetch("/private-api/getUsersList", { headers: { session_id } });
}

export async function setPasswordAsync(
  session_id: string,
  target_user: number,
  new_password: string,
): ReturnType<typeof fetch> {
  return fetch("/private-api/setPassword", {
    headers: {
      targetUser: "" + target_user,
      session_id: session_id,
      password: new_password,
    },
  });
}
